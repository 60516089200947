import { connect } from 'react-redux';

import MemberList from './MemberList';
import { updateMemberRole } from '../Members/actions';


export default connect(

    (state) => ({ 
        user: state.application.user
    }),

    (dispatch) => ({
        onUpdateMemberRole: (boxId, userId, role) => dispatch(updateMemberRole(boxId, userId, role))
    })
    
)(MemberList);
