import React, { Component } from 'react';


class JumboMessage extends Component {

    render() {

        const { message } = this.props;

        return (
            <div className="h-100 d-flex justify-content-center align-items-center"> 
                <div>{message}</div>
            </div>
        );

    }

}


export default JumboMessage;