import { store } from '../Application/store';

import { applicationNotificacion } from '../Application/Actions';


export const sleeper = (millis) => {
	return function (x) {
		return new Promise(resolve => setTimeout(() => resolve(x), millis));
	};
};


//
// grapher
//


const CONTENT_TYPE_JSON = {
	'Content-Type': 'application/json',
};


const ACCEPT_JSON = {
	'Accept': 'application/json'
};


export const grapher = (query, variables, uploads) => {
	return grapherToken(store.getState().application.token, query, variables, uploads);
};


export const publicGrapher = (query, variables, uploads) => {
	return grapherToken(null, query, variables, uploads);
};


const grapherTokenThen = (response) => {
	if (!response.ok) {
		if (response.status === 504) {
			return Promise.all([response, response.json()]);
		}
		const e = { code: 'internal-error' }
		store.dispatch(applicationNotificacion(e));
		return [response, { errors: [{ message: response.statusText, extensions: e }] }];
	}
	return Promise.all([response, response.json()]);
}


const grapherTokenThat = ([response, json]) => {
	if (response.status === 504) {
		const e = { code: 'network-error', name: json.name, info: json.address };
		store.dispatch(applicationNotificacion(e));
		return { errors: [{ message: response.statusText, extensions: e }] }
	}
	return json;
};


const grapherTokenCatch = (error) => {
	const e = { code: 'network-error', name: 'server', info: 'root' };
	store.dispatch(applicationNotificacion(e));
	return { errors: [{ message: error.message, extensions: e }] };
};


const grapherToken = (token, query, variables, uploads) => {

	if (!uploads) {

		return fetch('/graphql', {
			method: 'POST',
			headers: Object.assign({}, token ? { 'Authorization': 'Bearer ' + token } : null, CONTENT_TYPE_JSON, ACCEPT_JSON),
			body: JSON.stringify({ query, variables })
		})
			.then(grapherTokenThen)
			.then(grapherTokenThat)
			.catch(grapherTokenCatch);

	} else {

		const form = new FormData();

		form.append('query', query);
		form.append('variables', JSON.stringify(variables));
		uploads.forEach((u) => form.append(u.name, u.value, u.value.name));

		return fetch('/graphql', {
			method: 'POST',
			headers: Object.assign({}, token ? { 'Authorization': 'Bearer ' + token } : null, ACCEPT_JSON),
			body: form
		})
			.then(grapherTokenThen)
			.then(grapherTokenThat)
			.catch(grapherTokenCatch);

	}

};


export const dispatchInternalError = (dispatch, failure) => (error) => dispatch(failure([{ message: error.message, code: 'internal-error' }]));
