export const APPLICATION_INITIALIZE = 'APPLICATION_INITIALIZE';
export const APPLICATION_INITIALIZING = 'APPLICATION_INITIALIZING';
export const APPLICATION_INITIALIZE_SUCCESS = 'APPLICATION_INITIALIZE_SUCCESS';
export const APPLICATION_INITIALIZE_FAILURE = 'APPLICATION_INITIALIZE_FAILURE';

export const APPLICATION_LOGIN = 'APPLICATION_LOGIN';
export const APPLICATION_LOGGING_IN = 'APPLICATION_LOGGING_IN';
export const APPLICATION_LOGIN_SUCCESS = 'APPLICATION_LOGIN_SUCCESS';
export const APPLICATION_LOGIN_FAILURE = 'APPLICATION_LOGIN_FAILURE';
export const APPLICATION_LOGIN_RESET = 'APPLICATION_LOGIN_RESET';

export const APPLICATION_LOGOUT = 'APPLICATION_LOGOUT';

export const APPLICATION_REGISTER = 'APPLICATION_REGISTER';
export const APPLICATION_REGISTERING = 'APPLICATION_REGISTERING';
export const APPLICATION_REGISTER_SUCCESS = 'APPLICATION_REGISTER_SUCCESS';
export const APPLICATION_REGISTER_FAILURE = 'APPLICATION_REGISTER_FAILURE';
export const APPLICATION_REGISTER_RESET = 'APPLICATION_REGISTER_RESET';

export const APPLICATION_VERIFY = 'APPLICATION_VERIFY';
export const APPLICATION_VERIFYING = 'APPLICATION_VERIFYING';
export const APPLICATION_VERIFY_SUCCESS = 'APPLICATION_VERIFY_SUCCESS';
export const APPLICATION_VERIFY_FAILURE = 'APPLICATION_VERIFY_FAILURE';

export const APPLICATION_FORGOT = 'APPLICATION_FORGOT';
export const APPLICATION_FORGOTTING = 'APPLICATION_FORGOTTING';
export const APPLICATION_FORGOT_SUCCESS = 'APPLICATION_FORGOT_SUCCESS';
export const APPLICATION_FORGOT_FAILURE = 'APPLICATION_FORGOT_FAILURE';
export const APPLICATION_FORGOT_RESET = 'APPLICATION_FORGOT_RESET';

export const APPLICATION_GET_FORGOT = 'APPLICATION_GET_FORGOT';
export const APPLICATION_GETTING_FORGOT = 'APPLICATION_GETTING_FORGOT';
export const APPLICATION_GET_FORGOT_SUCCESS = 'APPLICATION_GET_FORGOT_SUCCESS';
export const APPLICATION_GET_FORGOT_FAILURE = 'APPLICATION_GET_FORGOT_FAILURE';

export const APPLICATION_GET_INVITE = 'APPLICATION_GET_INVITE';
export const APPLICATION_GETTING_INVITE = 'APPLICATION_GETTING_INVITE';
export const APPLICATION_GET_INVITE_SUCCESS = 'APPLICATION_GET_INVITE_SUCCESS';
export const APPLICATION_GET_INVITE_FAILURE = 'APPLICATION_GET_INVITE_FAILURE';

export const APPLICATION_ACCEPT = 'APPLICATION_ACCEPT';
export const APPLICATION_ACCEPTING = 'APPLICATION_ACCEPTING';
export const APPLICATION_ACCEPT_SUCCESS = 'APPLICATION_ACCEPT_SUCCESS';
export const APPLICATION_ACCEPT_FAILURE = 'APPLICATION_ACCEPT_FAILURE';

export const APPLICATION_RESET = 'APPLICATION_RESET';
export const APPLICATION_RESETTING = 'APPLICATION_RESETTING';
export const APPLICATION_RESET_SUCCESS = 'APPLICATION_RESET_SUCCESS';
export const APPLICATION_RESET_FAILURE = 'APPLICATION_RESET_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATING_PROFILE = 'UPDATING_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const APPLICATION_NOTIFICATION = 'APPLICATION_NOTIFICATION';
export const APPLICATION_NOTIFICATION_DISMISS = 'APPLICATION_NOTIFICATION_DISMISS';