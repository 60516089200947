import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import querystring from 'qs';

import Login from './Login';
import { login, loginReset } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

	connect(

		(state, ownProps) => {

			const qs = querystring.parse(ownProps.location.search, { ignoreQueryPrefix: true });

			const authenticate = qs.code ?
				{ status: 'F', errors: [{ extensions: { code: qs.code, name: qs.name, value: qs.value, info: qs.info } }] } :
				state.application.authenticate;

			return {

				action: state.application.authenticate,
				invalids: selectStatusInvalids(authenticate),

				provider: qs.provider,
				redirect: qs.redirect

			};

		},

		(dispatch, ownProps) => ({
			onSubmit: (username, password, remember, redirect) => dispatch(login(username, password, remember, ownProps.location, redirect)),
			onReset: () => dispatch(loginReset())
		}),

		(stateProps, dispatchProps, ownProps) => ({
			...ownProps, ...stateProps, ...dispatchProps,
			onSubmit: (username, password, remember) => dispatchProps.onSubmit(username, password, remember, stateProps.redirect)
		})

	),

	lifecycle({
		componentWillUnmount() {
			this.props.onReset();
		}
	})

)(Login);
