import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ConnectWithNimbox } from './_Components';


//
// ResetSuccess
//


class ResetSuccess extends Component {

    render() {

        return (
            <AuthenticateLayout>

                <h2>Clave cambiada</h2>

                <div className="form-group">
                    Tu clave fue cambiada. Ya puedes <Link to="/login">conectarte</Link> a nimbox con tu correo electrónico y clave.
                </div>

                <TermsOfUse />

                <div>
                    <ConnectWithNimbox />
                </div>

            </AuthenticateLayout>

        );

    }

}


//
// api
//


export default ResetSuccess;
