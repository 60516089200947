import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';

import classnames from 'classnames';

import { Page, Title, Container, Content } from '../Layouts/Helium';
import { SpinnerText } from '../Components/UI';

import userUnavailable from '../Images/user-unavailable.png';


class ProfilePage extends Component {

	state = {};

	static getDerivedStateFromProps(nextProps, prevState) {

		let state = {};

		if (prevState.prevFirstName !== nextProps.user.firstName) {
			state = {
				...state, prevFirstName: nextProps.user.firstName,
				firstName: nextProps.user.firstName || ''
			};
		}
		if (prevState.prevLastName !== nextProps.user.lastName) {
			state = {
				...state, prevLastName: nextProps.user.lastName,
				lastName: nextProps.user.lastName || ''
			};
		}
		if (prevState.prevPhoto !== nextProps.user.photo) {
			state = {
				...state, prevPhoto: nextProps.user.photo,
				url: nextProps.user.photo ? nextProps.user.photo.url : userUnavailable,
				file: null,
				scale: 1
			};
		}

		return state;

	}

	photoInput = React.createRef();
	photoEditor = React.createRef();

	handlePhotoChange = (e) => {

		if (e.target && e.target.files && e.target.files.length > 0) {
			this.setState({ url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
		}

	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { firstName, lastName, file } = this.state;
		this.props.onSubmit(firstName, lastName, file, this.photoEditor.current.getCroppingRect());
	};

	render() {

		const { update, invalids } = this.props;
		const { firstName, lastName, url, file, scale } = this.state;
		const { photoInput, photoEditor, handlePhotoChange, handleSubmit } = this;

		const disabled = (update.status === 'P');

		return (
			<Page>
				<div className="row">
					<div className="col-12 offset-lg-2 col-lg-8">
						<Title><h1 className="mt-5 mb-5">Perfil</h1></Title>
						<Container>
							<Content>

								<form onSubmit={handleSubmit}>
									<div className="form-row">

										<div className="col-sm-auto">
											<div className="form-group">
												<label><strong>Foto</strong></label>
												<input ref={photoInput} type="file" name="photo" onChange={handlePhotoChange} style={{ position: 'absolute', left: 0, right: 0, opacity: 0 }} />
												<div className="d-flex justify-content-center">
													<div className="inline-block form-group" style={{ width: 192 }}>
														<AvatarEditor ref={photoEditor} image={url} scale={scale} width={192} height={192} border={0} borderRadius={192 / 2} />
														{file && <input type="range" value={scale} min={1} max={10} step={0.1} onChange={(e) => this.setState({ scale: +e.target.value })} className="form-control" />}
														{file && <small className="form-text text-muted text-center">Ajusta la posición de la foto con el mouse. Ajusta el tamaño con el control de zoom.</small>}
													</div>
												</div>
												<button type="button" onClick={() => photoInput.current.click()} className="btn btn-secondary btn-block">Cambiar foto</button>
											</div>
										</div>

										<div className="col">

											<div className="form-group">
												<label><strong>Nombre</strong></label>
												<input type="text" name="firstName" value={firstName} onChange={(e) => this.setState({ firstName: e.target.value })}
													disabled={disabled}
													className={classnames('form-control', { 'is-invalid': invalids['firstName'] })}
												/>
												<div className="invalid-feedback">
													¿Cómo te llamas?
												</div>
											</div>

											<div className="form-group">
												<label><strong>Apellido</strong></label>
												<input type="text" name="lastName" value={lastName} onChange={(e) => this.setState({ lastName: e.target.value })}
													disabled={disabled}
													className={classnames('form-control', { 'is-invalid': invalids['lastName'] })}
												/>
												<div className="invalid-feedback">
													¿Cuál es tu apellido?
												</div>
											</div>

											<div className="form-group">
												<button type="submit" disabled={disabled} className="btn btn-primary">
													<SpinnerText spin={disabled}>Guardar</SpinnerText>
												</button>
											</div>

										</div>

									</div>
								</form>

							</Content>
						</Container>
					</div>
				</div>
			</Page>
		);

	}

}


export default ProfilePage;
