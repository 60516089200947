import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const AuthenticatedRoute = ({ authenticated, component: WrappedComponent, ...rest }) => {
	return (
		<Route {...rest} render={(props) => {
			if (authenticated) {
				return <WrappedComponent {...props} />;
			}
			return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		}} />
	);
};


export default connect(

	(state) => ({
		authenticated: !!state.application.token
	})

)(AuthenticatedRoute);
