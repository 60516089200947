import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import Register from './Register';
import { register, registerReset } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

    connect(

        (state) => ({
            action: state.application.register,
            invalids: selectStatusInvalids(state.application.register)
        }),

        (dispatch) => ({
            onSubmit: (emailAddress, firstName, lastName, password) => dispatch(register(emailAddress, firstName, lastName, password)),
            onReset: () => dispatch(registerReset())
        })

    ),

    lifecycle({
        componentWillUnmount() {
            this.props.onReset();
        }
    })

)(Register);
