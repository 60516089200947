import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { initialize } from './Actions';
import ApplicationNotificationContainer from './ApplicationNotificationContainer';
import ApplicationInitialized from './ApplicationInitialized';

import JumboMessage from '../Pages/JumboMessage';
import Delayed from '../Components/Delayed';


//
// Application
//


class Application extends Component {

    state = {};

    static getDerivedStateFromProps(props) {
        if (props.initialize.status === 'I') {
            props.doInitialize();
        }
        return null;
    }

    render() {

        const { initialize } = this.props;

        if (initialize.status === 'S') {
            return (
                <ApplicationNotificationContainer>
                    <ApplicationInitialized />
                </ApplicationNotificationContainer>
            );
        }

        if (initialize.status === 'F') {
            return (
                <JumboMessage message={
                    <div className="text-center">
                        <div>El servidor no está respondiendo.</div>
                        <div>Espera un momento e intenta de nuevo.</div>
                    </div>
                } />
            );
        }

        return (
            <Delayed wait={2000}>
                <JumboMessage message={
                    <div className="text-center">
                        <div><i className="fa fa-cog fa-fw fa-3x fa-spin" /></div>
                        <div>Inicializando...</div>
                    </div>
                } />
            </Delayed>
        );

    }

}


//
// api
//


export default withRouter(connect(

    (state) => ({
        initialize: state.application.initialize,
    }),

    (dispatch) => ({
        doInitialize: () => dispatch(initialize())
    })

)(Application));
