import { connect } from 'react-redux';

import CreateMember from './CreateMember';

import * as types from './types';
import { findUserByUsername, createMember, createMemberReset } from './actions'
import { selectStatusInvalids } from '../store/selectors/form';


export default connect(

    (state, ownProps) => {
        const permission = state.application.permissions.find((p) => p.box && (p.box.id === ownProps.box.id));
        return {

            permit: permission && (permission.role === 'OWNER' || permission.role === 'ADMINISTRATOR'),

            find: state.members.find,
            user: state.members.user,

            action: state.members.create,
            invalids: selectStatusInvalids(state.members.create)

        }
    },

    (dispatch, ownProps) => ({
        onFind: (emailAddress) => dispatch(findUserByUsername(emailAddress)),
        onSubmit: (emailAddress, role, firstName, lastName) => dispatch(createMember(ownProps.box.id, emailAddress, role, firstName, lastName))
            .then((action) => action.type === types.CREATE_MEMBER_SUCCESS),
        onReset: () => dispatch(createMemberReset())
    })

)(CreateMember);
