import { Component } from 'react';
import PropTypes from 'prop-types';


//
// Delayed
//


class Delayed extends Component {

    state = { show: false };

    componentDidMount() {
        this.mounted = true;
        const self = this;
        setTimeout(() => {
            if (self.mounted) {
                self.setState({ show: true });
            }
        }, this.props.wait);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {

        const { children } = this.props;
        const { show } = this.state;

        if (!show) {
            return null;
        };

        return children;

    }

}


//
// api
//


Delayed.propTypes = {
    wait: PropTypes.number.isRequired
};


export default Delayed;
