import React, { Component } from 'react';
import { Page, Title, Container, Content } from '../Layouts/Helium';

import classnames from 'classnames';
import _ from 'lodash';


class BoxesCreatePage extends Component {

    state = {
        name: '',
        errors: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.create.status === 'P') {
            return { errors: [] };
        }

        if (nextProps.create.status === 'S') {
            return { name: '' };
        }

        if (nextProps.create.status === 'F') {
            return { errors: nextProps.create.errors };
        }

        return null;

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.name);
    };

    render() {

        const { create } = this.props;
        const { name, errors } = this.state;
        const { handleSubmit } = this;

        const creating = create.status === 'P';
        const disabled = create.status === 'P';

        const duplicate = _.find(errors, (e) => e.code === 'plasma-duplicate');
        const invalid = _.chain(errors).filter((e) => e.code.endsWith('invalid-input')).keyBy('name').value();


        return (
            <Page>
                <Title><h1>Crear equipo</h1></Title>
                <Container>
                    <Content>

                        <p>Todavía no eres parte de un equipo.  Puedes crear uno e invitar a otros usuarios.</p>

                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" disabled={disabled} value={name} onChange={(e) => this.setState({ name: e.target.value.replace(/[^a-zA-Z0-9]/g, '') })} className={classnames('form-control', { 'is-invalid': duplicate || invalid['name'] })}/>
                                { duplicate &&
                                <div className="invalid-feedback">
                                    Ese nombre de equipo ya está tomado. Por favor intenta de nuevo con otro nombre.
                                </div>
                                }
                                { invalid['name'] &&
                                <div className="invalid-feedback">
                                    El nombre de un equipo debe comenzar con una letra y estar seguido de letras o números (sin espacios ni caracteres especiales).
                                </div>
                                }
                            </div>

                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <button type="submit" disabled={disabled} className="btn btn-primary">
                                        Agregar {creating && <i className="fa fa-spinner fa-spin"/>}
                                    </button>
                                </div>
                            </div>

                        </form>

                    </Content>
                </Container>
            </Page>
        );

    }

}


export default BoxesCreatePage;
