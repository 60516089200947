export const FIND_USER_BY_USERNAME = 'FIND_USER_BY_USERNAME';
export const FINDING_USER_BY_USERNAME = 'FINDING_USER_BY_USERNAME';
export const FIND_USER_BY_USERNAME_SUCCESS = 'FIND_USER_BY_USERNAME_SUCCESS';
export const FIND_USER_BY_USERNAME_FAILURE = 'FIND_USER_BY_USERNAME_FAILURE';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATING_MEMBER = 'CREATING_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';
export const CREATE_MEMBER_RESET = 'CREATE_MEMBER_RESET';

export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const UPDATING_MEMBER_ROLE = 'UPDATING_MEMBER_ROLE';
export const UPDATE_MEMBER_ROLE_SUCCESS = 'UPDATE_MEMBER_ROLE_SUCCESS';
export const UPDATE_MEMBER_ROLE_FAILURE = 'UPDATE_MEMBER_ROLE_FAILURE';
