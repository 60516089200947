import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import Forgot from './Forgot';
import { forgot, forgotReset } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

    connect(

        (state) => ({
            action: state.application.forgot,
            invalids: selectStatusInvalids(state.application.forgot)
        }),

        (dispatch) => ({
            onSubmit: (emailAddress) => dispatch(forgot(emailAddress)),
            onReset: () => dispatch(forgotReset())
        })

    ),

    lifecycle({
        componentWillUnmount() {
            this.props.onReset();
        }
    })

)(Forgot);
