import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import querystring from 'qs';

import Verify from './Verify';
import { verify } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

	connect(

		(state) => ({

			getAction: state.application.verify,
			getInvalids: selectStatusInvalids(state.application.verify)

		}),

		(dispatch, ownProps) => {
			const qs = querystring.parse(ownProps.location.search, { ignoreQueryPrefix: true });
			return {
				onGet: () => dispatch(verify(qs.token))
			};
		}

	),

	lifecycle({
		componentDidMount() {
			this.props.onGet();
		}
	})

)(Verify);
