import { connect } from 'react-redux';
import MemberApplicationBusinessPermission from './MemberApplicationBusinessPermission';

import { createApplicationPermission, deleteApplicationPermission, createApplicationBusinessPermission, deleteApplicationBusinessPermission } from './actions';


export default connect(

    (state, ownProps) => {
        const permission = state.application.permissions.find((p) => p.box && (p.box.id === ownProps.box.id));
        return { 
            authorized: permission && (permission.role === 'OWNER' || permission.role === 'ADMINISTRATOR'), 
        };
    },

    (dispatch) => ({
        onCreateApplicationPermission: (boxId, userId, applicationId) => dispatch(createApplicationPermission(boxId, userId, applicationId)),
        onDeleteApplicationPermission: (boxId, userId, applicationId) => dispatch(deleteApplicationPermission(boxId, userId, applicationId)),
        onCreateApplicationBusinessPermission: (boxId, userId, applicationId, businessId) => dispatch(createApplicationBusinessPermission(boxId, userId, applicationId, businessId)),
        onDeleteApplicationBusinessPermission: (boxId, userId, applicationId, businessId) => dispatch(deleteApplicationBusinessPermission(boxId, userId, applicationId, businessId))
    })

)(MemberApplicationBusinessPermission);
