import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import BusinessList from './BusinessList';


export default withRouter(connect(

    (state) => ({ 
        user: state.application.user
    })
    
)(BusinessList));
