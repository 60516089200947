export const CREATE_BOX_BUSINESS = 'CREATE_BOX_BUSINESS';
export const CREATING_BOX_BUSINESS = 'CREATING_BOX_BUSINESS';
export const CREATE_BOX_BUSINESS_SUCCESS = 'CREATE_BOX_BUSINESS_SUCCESS';
export const CREATE_BOX_BUSINESS_FAILURE = 'CREATE_BOX_BUSINESS_FAILURE';

export const GET_BUSINESS = 'GET_BUSINESS';
export const GETTING_BUSINESS = 'GETTING_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILURE = 'GET_BUSINESS_FAILURE';

export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const CREATING_BUSINESS = 'CREATING_BUSINESS';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE';
export const CREATE_BUSINESS_RESET = 'CREATE_BUSINESS_RESET';

export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const UPDATING_BUSINESS = 'UPDATING_BUSINESS';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';
export const UPDATE_BUSINESS_RESET = 'UPDATE_BUSINESS_RESET';
