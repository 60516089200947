import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';
import classnames from 'classnames';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ForgotMyPassword, RegisterWithNimbox, ConnectWithNimbox } from './_Components';


//
// Invite
//


class Invite extends Component {

    state = {
        user: null,
        emailAddress: '',
        firstName: '',
        lastName: '',
        password: ''
    };

    static getDerivedStateFromProps(props, state) {

        const { user } = props;
        if (user !== null && user !== state.user) {
            return {
                user,
                emailAddress: _.get(user, 'emails[0].address', ''),
                firstName: _.get(user, 'firstName', ''),
                lastName: _.get(user, 'lastName', ''),
                password: ''
            };
        }

        return null;

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { firstName, lastName, password } = this.state;
        this.props.onSubmit(firstName, lastName, password);
    };

    render() {

        const { getAction, action, invalids } = this.props;
        const { emailAddress, firstName, lastName, password } = this.state;
        const { handleSubmit } = this;

        // check that the get action is successful

        if (getAction.status === 'I' || getAction.status === 'P') {
            return (
                <AuthenticateLayout />
            );
        }

        if (getAction.status === 'F') {
            return (
                <AuthenticateLayout>

                    <h2>Confirma tu invitación</h2>

                    <div className="alert alert-danger text-center">
                        El link que presionaste para llegar aquí es inválido o expiró. Trata de recuperar tu clave o solicita una nueva invitación.
					</div>

                    <TermsOfUse />

                    <div>
                        <ForgotMyPassword />
                        <RegisterWithNimbox />
                        <ConnectWithNimbox />
                    </div>

                </AuthenticateLayout>
            );
        }

        // check that the update action is successful

        if (action.status === 'S') {
            return (<Redirect to="/invite/success" />);
        }

        const processing = (action.status === 'P');

        return (
            <AuthenticateLayout>

                <h2>Confirma tu invitación</h2>

                <form onSubmit={handleSubmit}>

                    <div className="form-row">

                        <div className="col-md form-group">
                            <label><strong>Nombre</strong></label>
                            <input type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                className={classnames('form-control', { 'is-invalid': invalids['firstName'] })}
                            />
                            <div className="invalid-feedback">
                                ¿Cómo te llamas?
							</div>
                        </div>

                        <div className="col-md form-group">
                            <label><strong>Apellido</strong></label>
                            <input type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                className={classnames('form-control', { 'is-invalid': invalids['lastName'] })}
                            />
                            <div className="invalid-feedback">
                                ¿Cuál es tu apellido?
							</div>
                        </div>

                    </div>

                    <div className="form-group">
                        <label><strong>Email</strong></label>
                        <input type="text"
                            name="emailAddress"
                            defaultValue={emailAddress}
                            disabled
                            className={classnames('form-control')}
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Clave</strong></label>
                        <input type="password"
                            name="password"
                            value={password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            className={classnames('form-control', { 'is-invalid': invalids['password'] })}
                        />
                        <div className="invalid-feedback">
                            La clave debe tener por lo menos 8 caracteres y debe contener por lo menos una letra minúscula, una mayúscula, un número y un caracter especial.
						</div>
                    </div>

                    <div className="form-group">
                        <button type="submit" disabled={processing} className="btn btn-primary btn-block">
                            {!processing ?
                                <span>Confirma mi invitación</span> :
                                <span><i className="fa fa-spinner fa-spin" /></span>
                            }
                        </button>
                    </div>

                    <TermsOfUse />

                    <div>
                        <ForgotMyPassword />
                        <RegisterWithNimbox />
                    </div>

                </form>

            </AuthenticateLayout>
        );

    }

}


export default Invite;
