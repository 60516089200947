import { connect } from 'react-redux';

import HomePage from "./HomePage";

export default connect(

    (state) => ({
        application: state.application
    })

)(HomePage);
