import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';

import { store } from './Application/store';

import Application from './Application/Application';
import { GOOGLE_TRACKING_CODE, APPLICATION } from './config';

import _ from 'lodash';

import 'font-awesome/css/font-awesome.css';
import './scss/styles.css';

// import registerServiceWorker from './registerServiceWorker';


// 
// initialize the application
//


const history = createBrowserHistory({ basename: '/' });

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(GOOGLE_TRACKING_CODE);
    function trackPage(page) {
        ReactGA.set({ page, dimension1: APPLICATION, userId: _.get(store.getState(), 'application.user.id') });
        ReactGA.pageview(page);
    }
    trackPage(history.location.pathname);
    history.listen((location) => {
        trackPage(location.pathname);
    });
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Application />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// registerServiceWorker();
