import React, { Component } from 'react';

import classnames from 'classnames';

class SelectLocus extends Component {

    render() {

        const { name, value, onChange, className } = this.props;

        return (
            <select name={name} value={value} onChange={onChange} className={classnames('form-control', className)}>
                <option>personal</option>
                <option>trabajo</option>
            </select>
        );

    }

}


export default SelectLocus;