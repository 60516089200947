import React, { Component } from 'react';

import { Page, Title, Container, Content } from '../Layouts/Helium';

import _ from 'lodash';
import { pickDifferences } from 'lomein';
import classnames from 'classnames';

import SelectLocus from '../Components/SelectLocus';
import SelectCountry from '../Components/SelectCountry';
import SelectCurrency from '../Components/SelectCurrency';


const emptyEmail = () => ({ empty: true, id: _.uniqueId('email-'), address: '', locus: '' });
const emptyPhone = () => ({ empty: true, id: _.uniqueId('phone-'), number: '', locus: '' });
const emptyAddress = () => ({ empty: true, id: _.uniqueId('address-'), street: '', reference: '', city: '', state: '', postal: '', country: 'XX', locus: '' });

class UpdateBusiness extends Component {

    state = {

        originalBusiness: null,

        business: {},

        emails: [],
        phones: [],
        addresses: []

    };

    static getDerivedStateFromProps(props, state) {

        const { business } = props;
        if (business != null && business !== state.originalBusiness) {

            return {

                originalBusiness: business,

                business: { ..._.pick(business, 'name', 'businessName', 'taxId'), country: _.get(business, 'country.code'), currency: _.get(business, 'currency.code'), original: business },

                emails: [...business.emails.map(email => ({ ..._.pick(email, 'id', 'address', 'locus'), original: email })), emptyEmail()],
                phones: [...business.phones.map(phone => ({ ..._.pick(phone, 'id', 'number', 'locus'), original: phone })), emptyPhone()],
                addresses: [...business.addresses.map(address => ({ ..._.pick(address, 'id', 'street', 'reference', 'city', 'state', 'postal', 'country', 'locus'), original: address })), emptyAddress()]

            };


        }

        return null;

    }

    // business

    handleChangeBusiness = (e) => {
        const { business } = this.state;
        this.setState({ business: { ...business, [e.target.name]: e.target.value } });
    };

    // emails

    handleChangeEmail = (e, i) => {
        const { emails } = this.state;
        if (emails[i].empty) {
            this.setState({ emails: [...emails.map(({ empty, ...email }, j) => j === i ? { action: 'create', ...email, [e.target.name]: e.target.value } : email), emptyEmail()] });
        } else {
            this.setState({ emails: emails.map((email, j) => j === i ? { action: 'update', ...email, [e.target.name]: e.target.value } : email) });
        }
    };

    handleDeleteEmail = (i) => {
        const { emails } = this.state;
        this.setState({ emails: [...emails.slice(0, i), { ...emails[i], action: 'delete' }, ...emails.slice(i + 1)] });
    };

    // phones

    handleChangePhone = (e, i) => {
        const { phones } = this.state;
        if (phones[i].empty) {
            this.setState({ phones: [...phones.map(({ empty, ...phone }, j) => j === i ? { action: 'create', ...phone, [e.target.name]: e.target.value } : phone), emptyPhone()] });
        } else {
            this.setState({ phones: phones.map((phone, j) => j === i ? { action: 'update', ...phone, [e.target.name]: e.target.value } : phone) });
        }
    };

    handleDeletePhone = (i) => {
        const { phones } = this.state;
        this.setState({ phones: [...phones.slice(0, i), { ...phones[i], action: 'delete' }, ...phones.slice(i + 1)] });
    };

    // addresses

    handleChangeAddress = (e, i) => {
        const { addresses } = this.state;
        if (addresses[i].empty) {
            this.setState({ addresses: [...addresses.map(({ empty, ...address }, j) => j === i ? { action: 'create', ...address, [e.target.name]: e.target.value } : address), emptyAddress()] });
        } else {
            this.setState({ addresses: addresses.map((address, j) => j === i ? { action: 'update', ...address, [e.target.name]: e.target.value } : address) });
        }
    };

    handleDeleteAddress = (i) => {
        const { addresses } = this.state;
        this.setState({ addresses: [...addresses.slice(0, i), { ...addresses[i], action: 'delete' }, ...addresses.slice(i + 1)] });
    };

    // submit

    handleSubmit = (e) => {

        e.preventDefault();

        const { business, emails, phones, addresses } = this.state;

        const emailActions = _.groupBy(_.filter(emails, 'action'), 'action');
        const phoneActions = _.groupBy(_.filter(phones, 'action'), 'action');
        const addressesActions = _.groupBy(_.filter(addresses, 'action'), 'action');

        this.props.onSubmit(
            { ...pickDifferences(business, business.original, ['id'], ['name', 'businessName', 'taxId']), ...pickDifferences(business, { country: _.get(business.original, 'country.code'), currency: _.get(business.original, 'currency.code') }, ['country', 'currency']) },
            {
                'delete': _.map(_.get(emailActions, 'delete', []), 'id'),
                'update': _.map(_.get(emailActions, 'update', []), (email) => pickDifferences(email, email.original, ['id'], ['address', 'locus'])),
                'create': _.map(_.get(emailActions, 'create', []), (email) => _.pick(email, ['id', 'address', 'locus']))
            }, {
                'delete': _.map(_.get(phoneActions, 'delete', []), 'id'),
                'update': _.map(_.get(phoneActions, 'update', []), (phone) => pickDifferences(phone, phone.original, ['id'], ['number', 'locus'])),
                'create': _.map(_.get(phoneActions, 'create', []), (phone) => _.pick(phone, ['id', 'number', 'locus']))
            },
            {
                'delete': _.map(_.get(addressesActions, 'delete', []), 'id'),
                'update': _.map(_.get(addressesActions, 'update', []), (address) => pickDifferences(address, address.original, ['id'], ['street', 'reference', 'city', 'state', 'postal', 'country', 'locus'])),
                'create': _.map(_.get(addressesActions, 'create', []), (address) => _.pick(address, ['id', 'street', 'reference', 'city', 'state', 'postal', 'country', 'locus']))
            }
        );

    }

    render() {

        const { updateAction, updateInvalids, onCancel } = this.props;
        const { business, emails, phones, addresses } = this.state;
        const { handleChangeBusiness, handleChangeEmail, handleDeleteEmail, handleChangePhone, handleDeletePhone, handleChangeAddress, handleDeleteAddress } = this;
        const { handleSubmit } = this;

        return (
            <Page>

                <Title><h1 className="mt-5 mb-5">{business.name}</h1></Title>

                <form onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col form-group">
                            {(updateAction.status === 'F') && <div className="btn btn-danger btn-block text-truncate">Hubo un error</div>}
                        </div>
                        <div className="col-auto form-group">
                            <button type="button" onClick={onCancel} className="btn btn-secondary mr-3">Cancelar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>

                    <Container>
                        <Content>


                            <div className="mb-5">

                                <div className="form-row">
                                    <div className="col-12 col-md-4 form-group">
                                        <label>Nombre corto</label>
                                        <input type="text"
                                            name="name"
                                            value={business.name}
                                            onChange={handleChangeBusiness}
                                            className={classnames('form-control', { 'is-invalid': updateInvalids['name'] })}
                                        />
                                    </div>
                                    <div className="col2-12 col-md-8 form-group">
                                        <label>Nombre comercial</label>
                                        <input type="text"
                                            name="businessName"
                                            value={business.businessName}
                                            onChange={handleChangeBusiness}
                                            className={classnames('form-control', { 'is-invalid': updateInvalids['businessName'] })}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-12 col-md form-group">
                                        <label>Identificación fiscal</label>
                                        <input type="text"
                                            name="taxId"
                                            value={business.taxId}
                                            onChange={handleChangeBusiness}
                                            className={classnames('form-control', { 'is-invalid': updateInvalids['taxId'] })}
                                        />
                                    </div>
                                    <div className="col-12 col-md form-group">
                                        <label>País</label>
                                        <SelectCountry name="country" onChange={handleChangeBusiness} value={business.country} />
                                    </div>
                                    <div className="col-12 col-md form-group">
                                        <label>Moneda</label>
                                        <SelectCurrency name="currency" onChange={handleChangeBusiness} value={business.currency} />
                                    </div>
                                </div>

                            </div>


                            <div className="row">
                                <div className="col-12 col-md-6">

                                    <h3>Emails</h3>
                                    <fieldset>
                                        {emails.map((email, i) => (email.action !== 'delete') &&
                                            <EmailLine key={email.id}
                                                value={email}
                                                onChange={(e) => handleChangeEmail(e, i)}
                                                onDelete={() => handleDeleteEmail(i)}
                                                invalids={updateInvalids}
                                            />
                                        )}
                                    </fieldset>

                                    <h3>Teléfonos</h3>
                                    <fieldset>
                                        {phones.map((phone, i) => (phone.action !== 'delete') &&
                                            <PhoneLine key={phone.id}
                                                value={phone}
                                                onChange={(e) => handleChangePhone(e, i)}
                                                onDelete={() => handleDeletePhone(i)}
                                                invalids={updateInvalids}
                                            />
                                        )}
                                    </fieldset>

                                </div>
                                <div className="col-12 col-md-6">

                                    <h3>Direcciones</h3>
                                    <fieldset>
                                        {addresses.map((address, i) => (address.action !== 'delete') &&
                                            <AddressLine key={address.id}
                                                value={address}
                                                onChange={(e) => handleChangeAddress(e, i)}
                                                onDelete={() => handleDeleteAddress(i)}
                                                invalids={updateInvalids}
                                            />
                                        )}
                                    </fieldset>

                                </div>
                            </div>

                        </Content>
                    </Container>

                </form>

            </Page>
        );

    }

}


const EmailLine = ({ value, onChange, onDelete, invalids }) => (

    <div className="d-flex align-items-center form-group">

        <div className="flex-fill form-row">
            <div className="col-7">
                <input type="text"
                    name="address"
                    value={value.address}
                    onChange={onChange}
                    className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`emails[${value.id}].address`] })}
                />
            </div>
            <div className="col-5">
                <SelectLocus name="locus" value={value.locus} onChange={onChange} className="form-control-sm" />
            </div>
        </div>

        <div className={classnames('ml-2', { 'invisible': value.empty })}>
            <div className="text-center text-danger" onClick={onDelete} style={{ cursor: 'pointer' }}><i className="fa fa-times fa-fw" /></div>
        </div>

    </div>

);


const PhoneLine = ({ value, onChange, onDelete, invalids }) => (

    <div className="d-flex align-items-center form-group">

        <div className="flex-fill form-row">
            <div className="col-7">
                <input type="text"
                    name="number"
                    value={value.number}
                    onChange={onChange}
                    className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`phones[${value.id}].number`] })}
                />
            </div>
            <div className="col-5">
                <SelectLocus name="locus" value={value.locus} onChange={onChange} className="form-control-sm" />
            </div>
        </div>

        <div className={classnames('ml-2', { 'invisible': value.empty })}>
            <div className="text-center text-danger" onClick={onDelete} style={{ cursor: 'pointer' }}><i className="fa fa-times fa-fw" /></div>
        </div>

    </div>

);


const AddressLine = ({ value, onChange, onDelete, invalids }) => (

    <div className="d-flex align-items-baseline form-group">

        <div className="flex-fill">

            <div className="form-group">
                <label>Calle</label>
                <textarea
                    name="street"
                    value={value.street}
                    onChange={onChange}
                    className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`addresses[${value.id}].street`] })}
                />
            </div>

            <div className="form-group">
                <label>Referencia</label>
                <textarea
                    name="reference"
                    value={value.reference}
                    onChange={onChange}
                    className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`addresses[${value.id}].rference`] })}
                />
            </div>

            <div className="form-row">
                <div className="col form-group">
                    <label>Ciudad</label>
                    <input type="text"
                        name="city"
                        value={value.city}
                        onChange={onChange}
                        className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`addresses[${value.id}].city`] })}
                    />
                </div>
                <div className="col form-group">
                    <label>Estado</label>
                    <input type="text"
                        name="state"
                        value={value.estate}
                        onChange={onChange}
                        className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`addresses[${value.id}].estate`] })}
                    />
                </div>
                <div className="col form-group">
                    <label>Postal</label>
                    <input type="text"
                        name="postal"
                        value={value.postal}
                        onChange={onChange}
                        className={classnames('form-control form-control-sm', { 'is-invalid': invalids[`addresses[${value.id}].postal`] })}
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="col form-group">
                    <label>País</label>
                    <SelectCountry name="country" value={value.country} onChange={onChange} className="form-control-sm" />
                </div>
                <div className="col form-group">
                    <label>Locus</label>
                    <SelectLocus name="locus" value={value.locus} onChange={onChange} className="form-control-sm" />
                </div>
            </div>

        </div>

        <div className={classnames('ml-2', { 'invisible': value.empty })}>
            <div className="text-center text-danger" onClick={onDelete} style={{ cursor: 'pointer' }}><i className="fa fa-times fa-fw" /></div>
        </div>

    </div>

);


export default UpdateBusiness;
