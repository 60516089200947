import { connect } from 'react-redux';

import ProfilePage from './ProfilePage';
import { updateProfile } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default connect(

    (state) => ({
        user: state.application.user,
        update: state.application.updateProfile,
        invalids: selectStatusInvalids(state.application.updateProfile)
    }),

    (dispatch) => ({
        onSubmit: (userId, firstName, lastName, photo, photoRect) => dispatch(updateProfile(userId, firstName, lastName, photo, photoRect))
    }),

    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (firstName, lastName, photo, photoRect) => dispatchProps.onSubmit(stateProps.user.id, firstName, lastName, photo, photoRect)
    })

)(ProfilePage);
