import * as types from './types';
import { grapher } from '../util/request';


export const BOX_FIELDS = 'id name user { id firstName lastName } businesses { id name user { id firstName lastName } businessName taxId } applications { id application { id name } } permissions { id application { id } business { id user { id } } }';
export const MEMBER_FIELDS = 'id user { id firstName lastName emails { id address verified } } role applications { id application { id } } permissions { id application { id } business { id user { id } } }';


//
// permissions
//


export const getBoxPermissions = (id) => (dispatch) => {
    dispatch(gettingBoxPermissions());
    return grapher(
        `query GetBox($id: ID!) {
            box: getBox(id: $id) {
                ${BOX_FIELDS}
                members { ${MEMBER_FIELDS} }
            }
        }`, { id }
    )
        .then(json => {
            if (json.errors) { return dispatch(getBoxPermissionsFailure(json.errors)); }
            dispatch(getBoxPermissionsSuccess(json.data.box));
        });
};


export const gettingBoxPermissions = () => ({
    type: types.GETTING_BOX_PERMISSIONS
});


export const getBoxPermissionsSuccess = (box) => ({
    type: types.GET_BOX_PERMISSIONS_SUCCESS,
    box
});


export const getBoxPermissionsFailure = (errors) => ({
    type: types.GET_BOX_PERMISSIONS_FAILURE,
    errors
});


//
// box permissions
//


export const createBoxApplicationPermission = (boxId, applicationId) => (dispatch) => {
    return grapher(
        `mutation CreateBoxApplicationPermission($boxId: ID!, $applicationId: ID!) {
            box: createBoxApplicationPermission(boxId: $boxId, applicationId: $applicationId) {
                ${BOX_FIELDS}
                members { ${MEMBER_FIELDS} }
            }
        }`, { boxId, applicationId }
    )
        .then(json => dispatch(createBoxApplicationPermissionSuccess(json.data.box)));
};


export const createBoxApplicationPermissionSuccess = (box) => ({
    type: types.CREATE_BOX_APPLICATION_PERMISSION_SUCCESS,
    box
});


export const deleteBoxApplicationPermission = (boxId, applicationId) => (dispatch) => {
    return grapher(
        `mutation DeleteBoxApplicationPermission($boxId: ID!, $applicationId: ID!) {
            box: deleteBoxApplicationPermission(boxId: $boxId, applicationId: $applicationId) {
                ${BOX_FIELDS}
                members { ${MEMBER_FIELDS} }
            }
        }`, { boxId, applicationId }
    )
        .then(json => dispatch(deleteBoxApplicationPermissionSuccess(json.data.box)));
};


export const deleteBoxApplicationPermissionSuccess = (box) => ({
    type: types.DELETE_BOX_APPLICATION_PERMISSION_SUCCESS,
    box
});


export const createBoxApplicationBusinessPermission = (boxId, applicationId, businessId) => (dispatch) => {
    return grapher(
        `mutation CreateBoxApplicationBusinessPermission($boxId: ID!, $applicationId: ID!, $businessId: ID!) {
            box: createBoxApplicationBusinessPermission(boxId: $boxId, applicationId: $applicationId, businessId: $businessId) {
                ${BOX_FIELDS}
                members { ${MEMBER_FIELDS} }
            }
        }`, { boxId, applicationId, businessId }
    )
        .then(json => dispatch(createBoxApplicationBusinessPermissionSuccess(json.data.box)));
};


export const createBoxApplicationBusinessPermissionSuccess = (box) => ({
    type: types.CREATE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS,
    box
});


export const deleteBoxApplicationBusinessPermission = (boxId, applicationId, businessId) => (dispatch) => {
    return grapher(
        `mutation DeleteBoxApplicationBusinessPermission($boxId: ID!, $applicationId: ID!, $businessId: ID!) {
            box: deleteBoxApplicationBusinessPermission(boxId: $boxId, applicationId: $applicationId, businessId: $businessId) {
                ${BOX_FIELDS}
                members { ${MEMBER_FIELDS} }
            }
        }`, { boxId, applicationId, businessId }
    )
        .then(json => dispatch(deleteBoxApplicationBusinessPermissionSuccess(json.data.box)));
};


export const deleteBoxApplicationBusinessPermissionSuccess = (box) => ({
    type: types.DELETE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS,
    box
});


//
// member permissions
//


export const createApplicationPermission = (boxId, userId, applicationId) => (dispatch) => {
    return grapher(
        `mutation CreateMemberApplicationPermission($boxId: ID!, $userId: ID!, $applicationId: ID!) {
            member: createMemberApplicationPermission(boxId: $boxId, userId: $userId, applicationId: $applicationId) {
                ${MEMBER_FIELDS}
            }
        }`, { boxId, userId, applicationId }
    )
        .then(json => dispatch(createApplicationPermissionSuccess(json.data.member)));
};


export const createApplicationPermissionSuccess = (member) => ({
    type: types.CREATE_MEMBER_APPLICATION_PERMISSION_SUCCESS,
    member
});


export const deleteApplicationPermission = (boxId, userId, applicationId) => (dispatch) => {
    return grapher(
        `mutation DeleteMemberApplicationPermission($boxId: ID!, $userId: ID!, $applicationId: ID!) {
            member: deleteMemberApplicationPermission(boxId: $boxId, userId: $userId, applicationId: $applicationId) {
                ${MEMBER_FIELDS}
            }
        }`, { boxId, userId, applicationId }
    )
        .then(json => dispatch(deleteApplicationPermissionSuccess(json.data.member)));
};


export const deleteApplicationPermissionSuccess = (member) => ({
    type: types.DELETE_MEMBER_APPLICATION_PERMISSION_SUCCESS,
    member
});


export const createApplicationBusinessPermission = (boxId, userId, applicationId, businessId) => (dispatch) => {
    return grapher(
        `mutation CreateMemberApplicationBusinessPermission($boxId: ID!, $userId: ID!, $applicationId: ID!, $businessId: ID!) {
            member: createMemberApplicationBusinessPermission(boxId: $boxId, userId: $userId, applicationId: $applicationId, businessId: $businessId) {
                ${MEMBER_FIELDS}
            }
        }`, { boxId, userId, applicationId, businessId }
    )
        .then(json => dispatch(createApplicationBusinessPermissionSuccess(json.data.member)));
};


export const createApplicationBusinessPermissionSuccess = (member) => ({
    type: types.CREATE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS,
    member
});


export const deleteApplicationBusinessPermission = (boxId, userId, applicationId, businessId) => (dispatch) => {
    return grapher(
        `mutation DeleteMemberApplicationBusinessPermission($boxId: ID!, $userId: ID!, $applicationId: ID!, $businessId: ID!) {
            member: deleteMemberApplicationBusinessPermission(boxId: $boxId, userId: $userId, applicationId: $applicationId, businessId: $businessId) {
                ${MEMBER_FIELDS}
            }
        }`, { boxId, userId, applicationId, businessId }
    )
        .then(json => dispatch(deleteApplicationBusinessPermissionSuccess(json.data.member)));
};


export const deleteApplicationBusinessPermissionSuccess = (member) => ({
    type: types.DELETE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS,
    member
});


//
// createBox
//


export const createBox = (name, success) => (dispatch) => {
    dispatch(creatingBox());
    return grapher(
        `mutation CreateBox($name: String!) {
            box: createBox(name: $name) {
                id name
                members { 
                    id role box { id name }                    
                }
            }
        }`, { name }
    )
        .then(json => {
            if (json.errors) { return dispatch(createBoxFailure(json.errors)); }
            return dispatch(createBoxSuccess(json.data.box));
        });
};


export const creatingBox = () => ({
    type: types.CREATING_BOX
});


export const createBoxSuccess = (box) => ({
    type: types.CREATE_BOX_SUCCESS,
    box
});


export const createBoxFailure = (errors) => ({
    type: types.CREATE_BOX_FAILURE,
    errors
});


export const createBoxReset = () => ({
    type: types.CREATE_BOX_RESET
});
