import React, {    Component, FunctionComponent } from 'react';

import { Neon } from 'gizmo';

import Navigator from '../Application/Navigator/Navigator';
import Footer from './Footer';


const ContainerLayout: FunctionComponent<{}> = ({ children }) => (
    <Neon.Page>
        <Neon.Page.Header className="hidden-print">
            <Navigator />
        </Neon.Page.Header>
        <Neon.Page.Main>
            <Neon.Page.Content>
                <div className="container">
                    {children}
                </div>
            </Neon.Page.Content>
        </Neon.Page.Main>
        <Footer />
    </Neon.Page>
);


export default ContainerLayout;


export const withContainerLayout = <P extends object> (Child: React.ComponentType<P>) => 

    class WithContainerLayout extends Component<P> {

        render() {
            return <ContainerLayout {...this.props}><Child {...this.props} /></ContainerLayout>;
        }

    };
