import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import classnames from 'classnames';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, RegisterWithNimbox, ConnectWithNimbox } from './_Components';


//
// Forgot
//


class Forgot extends Component {

	state = {
		emailAddress: ''
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { emailAddress } = this.state;
		this.props.onSubmit(emailAddress);
	};

	render() {

		const { action, invalids } = this.props;
		const { emailAddress } = this.state;
		const { handleSubmit } = this;

		if (action.status === 'S') {
			return (<Redirect to="/password/forgot/success" />);
		}

		const processing = (action.status === 'P');

		return (
			<AuthenticateLayout>

				<h2>Olvidé mi clave</h2>

				<form onSubmit={handleSubmit}>

					<div className="form-group">
						<label><strong>Email</strong></label>
						<input type="text"
							name="emailAddress"
							value={emailAddress}
							onChange={(e) => this.setState({ emailAddress: e.target.value.replace(/\s/g, '') })}
							disabled={processing}
							className={classnames('form-control', { 'is-invalid': invalids['emailAddress'] })}
						/>
						<div className="invalid-feedback">
							Por favor introduce una dirección de email válida.
						</div>
					</div>

					<div className="form-group">
						<button type="submit" disabled={processing} className="btn btn-primary btn-block">
							{!processing ?
								<span>Envíame un email para recuperar mi clave</span> :
								<span><i className="fa fa-spinner fa-spin" /></span>
							}
						</button>
					</div>

					<TermsOfUse />

					<div>
						<RegisterWithNimbox />
						<ConnectWithNimbox />
					</div>

				</form>

			</AuthenticateLayout>
		);

	}

}


//
// api
//


Forgot.propTypes = {

	action: PropTypes.object.isRequired,
    invalids: PropTypes.object,

	onSubmit: PropTypes.func.isRequired,

};


export default Forgot;
