import * as types from './types';

const initial = {

    get: { status: 'I' },
    create: { status: 'I' },
    update: { status: 'I' },

    business: null

};


const reducer = (state = initial, action) => {

    switch (action.type) {

        case types.GETTING_BUSINESS:
            return { ...state, get: { status: 'P' }, business: null };

        case types.GET_BUSINESS_SUCCESS:
            return { ...state, get: { status: 'S' }, business: action.business };

        case types.GET_BUSINESS_FAILURE:
            return { ...state, get: { status: 'F', errors: action.errors } };

        case types.CREATING_BUSINESS:
            return { ...state, create: { status: 'P', errors: state.create.errors }, business: null };

        case types.CREATE_BUSINESS_SUCCESS:
            return { ...state, create: { status: 'S' }, business: action.business };

        case types.CREATE_BUSINESS_FAILURE:
            return { ...state, create: { status: 'F', errors: action.errors } };

        case types.CREATE_BUSINESS_RESET:
            return { ...state, create: { status: 'I' } };

        case types.UPDATING_BUSINESS:
            return { ...state, update: { status: 'P', errors: state.update.errors }, business: null };

        case types.UPDATE_BUSINESS_SUCCESS:
            return { ...state, update: { status: 'S' }, business: action.business };

        case types.UPDATE_BUSINESS_FAILURE:
            return { ...state, update: { status: 'F', errors: action.errors } };

        case types.UPDATE_BUSINESS_RESET:
            return { ...state, update: { status: 'I' } };


        default:
            return state;

    }

};


export default reducer;
