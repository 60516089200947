import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import { Dropdown } from 'gizmo';
import userUnavailable from '../../Images/user-unavailable.png';


class UserIdentifier extends Component {

    state = { show: false };

    handleHide = () => {
        this.setState({ show: false });
    }

    handleLogout = () => {
        this.handleHide()
        this.props.logout();
    }

    render() {

        const { user } = this.props;
        const { show } = this.state;
        const { handleHide, handleLogout } = this;

        if (!user) { return null; }

        return (
            <Dropdown show={show} placement="bottom-end" eventsEnabled={true} resize={true} onDismiss={handleHide}>
                <div onClick={() => this.setState({ show: !show })} className={classnames('dash-navigator-item', 'dash-hover', { 'dash-dropped': show }, 'dropdown-toggle')}>
                    <img src={(user && user.photo && user.photo.url) || userUnavailable}
                        alt="avatar" className="dash-navigator-avatar"
                    />
                    {user.firstName} ({user.id})
                </div>
                <div className="dash-dropdown-menu">
                    <Link to="/profile" onClick={handleHide} className="dropdown-item">perfil</Link>
                    <Link to="/boxes" onClick={handleHide} className="dropdown-item">equipos</Link>
                    <Link to="#logout" onClick={handleLogout} className="dropdown-item">logout</Link>
                </div>
            </Dropdown>
        );
    };

}


export default UserIdentifier;
