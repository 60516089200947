import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import BoxesCreatePageContainer from './BoxesCreatePageContainer';


export default connect(
    (state) => ({
        permissions: state.application.permissions
    })
)(
    ({ permissions }) => {
        if (permissions.length > 0 && permissions[0].box) {
            return <Redirect to={`/boxes/${permissions[0].box.id}/members`} />;
        }
        return <BoxesCreatePageContainer />;
    }
);
