import React, { Component } from 'react';

import classnames from 'classnames';
import _ from 'lodash';


const initial = {
    show: false,
    name: '',
    errors: []
};


class CreateBox extends Component {

    state = { ...initial };

    handleToggleShow = (e) => {
        if (this.state.show) {
            this.setState({ ...initial, show: false });
            this.props.onReset();
        } else {
            this.setState({ ...initial, show: true });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.name)
            .then((success) => success && this.handleToggleShow());

    };

    render() {

        const { action, invalids, className } = this.props;
        const { show, name } = this.state;
        const { handleToggleShow, handleSubmit } = this;

        const creating = action.status === 'P';
        const disabled = creating;

        return (
            <div className={classnames('card', className)}>

                <div className="card-header">
                    <h5 className="mb-0">
                        <button onClick={handleToggleShow} className="btn btn-link">Crear Equipo</button>
                    </h5>
                </div>

                <div className={classnames('collapse', { 'show': show })}>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" value={name} disabled={disabled}
                                    onChange={(e) => this.setState({ name: e.target.value.replace(/[^a-zA-Z0-9]/g, '') })}
                                    className={classnames('form-control', { 'is-invalid': invalids['name'] })}
                                />
                                <div className="invalid-feedback">
                                    {{
                                        'box.name.error.duplicate': 'Ese nombre de equipo ya está tomado. Por favor intenta de nuevo con otro nombre.'
                                    }[_.get(invalids, 'name.value')] || 'El nombre de un equipo debe comenzar con una letra y estar seguido de letras o números (sin espacios ni caracteres especiales).'}
                                </div>
                                <small className="form-text text-muted">
                                    Este nombre identifica tu equipo de trabajo y debe ser un nombre único y corto (sin espacios ni caracteres especiales).
                                </small>
                            </div>

                            <div>
                                <button type="submit" disabled={disabled} className="btn btn-primary mr-2">
                                    Agregar {creating && <i className="fa fa-spinner fa-spin" />}
                                </button>
                                <button type="button" onClick={handleToggleShow} disabled={disabled} className="btn btn-secondary">Cancelar</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        );

    }

}


export default CreateBox;
