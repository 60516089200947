
import { combineReducers } from 'redux';

import application from './Reducer';
import boxes from '../Boxes/reducer';
import businesses from '../Businesses/reducer';
import members from '../Members/reducer';

export default combineReducers({
    application,
    boxes,
    businesses,
    members
});
