import { connect } from 'react-redux';
import CreateBusiness from './CreateBusiness';

import * as types from './types';
import { createBusiness, createBusinessReset } from './actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default connect(

    (state, ownProps) => {
        const permission = state.application.permissions.find((p) => p.box && (p.box.id === ownProps.box.id));
        return {
            permit: permission && (permission.role === 'OWNER' || permission.role === 'ADMINISTRATOR'),
            action: state.businesses.create,
            invalids: selectStatusInvalids(state.businesses.create)
        };
    },

    (dispatch, ownProps) => ({
        onSubmit: (name, businessName, taxId) => dispatch(createBusiness(ownProps.box.id, name, businessName, taxId))
            .then((action) => action.type === types.CREATE_BUSINESS_SUCCESS),
        onReset: () => dispatch(createBusinessReset())
    })

)(CreateBusiness);
