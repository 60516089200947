import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, RegisterWithNimbox, ConnectWithNimbox } from './_Components';


//
// Verify
//


class Verify extends Component {

	render() {

		const { getAction, getInvalids } = this.props;

		return (
			<AuthenticateLayout>

				<h2>Verifica tu email</h2>

				{(getAction.status === 'S') &&
					<div className="alert alert-success text-center form-group">
						Gracias por verificar tu email. Ya puedes conectarte a nimbox <Link to='/'>aquí</Link>.
					</div>
				}

				{(getAction.status === 'F' || getInvalids['emailAddressVerifyToken']) &&
					<div className="alert alert-danger text-center">
						El link que presionaste para llegar aquí tiene un token inválido o que expiró. Ve a tu perfil y selecciona enviar nuevo email de verificación.
					</div>
				}

				<TermsOfUse />

				<div>
					<RegisterWithNimbox />
					<ConnectWithNimbox />
				</div>

			</AuthenticateLayout>
		);

	}

}


//
// api
//


Verify.propTypes = {

	action: PropTypes.object.isRequired,
	invalids: PropTypes.object.isRequired

};


export default Verify;
