import * as types from './Types';
import { grapher, publicGrapher } from '../util/request';

import _ from 'lodash';

export const USER_FIELDS = 'id firstName lastName photo { url } emails { id address verified }';


// initialize


export const initialize = () => (dispatch) => {
	fetch('/self', {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		credentials: 'include'
	})
		.then((response) => {
			if (!response.ok) {
				if (response.status === 504) { throw new Error('server timeout'); }
				return null;
			}
			return response.json();
		})
		.then((json) => {
			if (json && json.user) {
				dispatch(loginSuccess(json.user, json.permissions));
			}
			dispatch(initializeSuccess());
			dispatch(loginReset());
		})
		.catch((error) => {
			dispatch(initializeFailure(error));
		});
};


export const initializeSuccess = () => ({
	type: types.APPLICATION_INITIALIZE_SUCCESS,
});


export const initializeFailure = (error) => ({
	type: types.APPLICATION_INITIALIZE_FAILURE,
	error
});


// login


export const login = (username, password, remember, location, redirect) => (dispatch) => {
	dispatch(logingIn());
	fetch('/authenticate/nimbox', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		credentials: 'include',
		body: JSON.stringify({ username, password, remember })
	})
		.then(response => {
			if (!response.ok) {
				if (response.status === 504) { throw new Error('server timeout') }
				dispatch(loginFailure([
					{ message: response.statusText, extensions: { code: 'plasma-invalid-input', name: 'username', value: 'username.error.invalid' } },
					{ message: response.statusText, extensions: { code: 'plasma-invalid-input', name: 'password', value: 'password.error.invalid' } }
				]));
				return null;
			}
			return response.json();
		})
		.then(result => {

			if (!result) {
				return;
			}

			dispatch(loginSuccess(result));

			if (location && location.state && location.state.from) {
				window.location.href = location.state.from.pathname;
				return;
			}

			if (redirect) {
				window.location.href = redirect;
				return;
			}

			window.location.href = '/';

		})
		.catch(error => {
			dispatch(applicationNotificacion({ code: 'network-error', name: 'server', info: 'root' }))
			dispatch(loginFailure([
				{ message: error.message, extensions: { code: 'plasma-invalid-input', name: 'username', value: 'username.error.invalid' } },
				{ message: error.message, extensions: { code: 'plasma-invalid-input', name: 'password', value: 'password.error.invalid' } }
			]));
		});
};


export const logingIn = () => ({
	type: types.APPLICATION_LOGGING_IN
});


export const loginSuccess = (user, permissions) => ({
	type: types.APPLICATION_LOGIN_SUCCESS,
	user,
	permissions
});


export const loginFailure = (errors) => ({
	type: types.APPLICATION_LOGIN_FAILURE,
	errors
});


export const loginReset = () => ({
	type: types.APPLICATION_LOGIN_RESET
});


export const logout = () => (dispatch) => {
	fetch('/authenticate/nimbox/logout', {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
	})
		.then(() => { window.location = '/'; })
		.catch(() => { window.location = '/'; });
};


// register


export const register = (emailAddress, firstName, lastName, password) => (dispatch) => {
	dispatch(registering());
	return publicGrapher(
		`mutation CreateUser($emailAddress: String!, $firstName: String!, $lastName: String!, $password: String!) {
			user: createUser(emailAddress: $emailAddress, firstName: $firstName, lastName: $lastName, password: $password) {
				${USER_FIELDS}
			}
		}`, { emailAddress, firstName, lastName, password })
		.then(json => {
			if (json.errors) { return dispatch(registerFailure(json.errors)); }
			return dispatch(registerSuccess());
		});
};


export const registering = () => ({
	type: types.APPLICATION_REGISTERING
});


export const registerSuccess = () => ({
	type: types.APPLICATION_REGISTER_SUCCESS,
});


export const registerFailure = (errors) => ({
	type: types.APPLICATION_REGISTER_FAILURE,
	errors
});


export const registerReset = () => ({
	type: types.APPLICATION_REGISTER_RESET
});


// verify


export const verify = (token) => (dispatch) => {
	dispatch(verifying());
	return publicGrapher(
		`mutation UpdateUserEmailAddressVerify($token: String!) {
			user: updateUserEmailAddressVerify(token: $token) {
				${USER_FIELDS}
			}
		}`, { token })
		.then(json => {
			if (json.errors) { return dispatch(verifyFailure(json.errors)); }
			return dispatch(verifySuccess());
		});
};


export const verifying = () => ({
	type: types.APPLICATION_VERIFYING
});


export const verifySuccess = () => ({
	type: types.APPLICATION_VERIFY_SUCCESS
});


export const verifyFailure = (errors) => ({
	type: types.APPLICATION_VERIFY_FAILURE,
	errors
});


// forgot


export const forgot = (emailAddress) => (dispatch) => {
	dispatch(forgotting());
	return publicGrapher(
		`mutation UpdateUserPasswordForgot($emailAddress: String!) {
			user: updateUserPasswordForgot(username: $emailAddress) {
				${USER_FIELDS}
			}
		}`, { emailAddress })
		.then(json => {
			if (json.errors) { return dispatch(forgotFailure(json.errors)); }
			return dispatch(forgotSuccess());
		});
};


export const forgotting = () => ({
	type: types.APPLICATION_FORGOTTING
});


export const forgotSuccess = () => ({
	type: types.APPLICATION_FORGOT_SUCCESS
});


export const forgotFailure = (errors) => ({
	type: types.APPLICATION_FORGOT_FAILURE,
	errors
});


export const forgotReset = () => ({
	type: types.APPLICATION_FORGOT_RESET
});


// get forgot


export const getForgot = (token) => (dispatch) => {
	dispatch(gettingForgot());
	return publicGrapher(
		`query GetUserByPasswordResetToken($token: String!) {
			user: getUserByPasswordResetToken(token: $token) {
				${USER_FIELDS}
			}
		}`, { token })
		.then(json => {
			if (json.errors) { return dispatch(getForgotFailure(json.errors)); }
			return dispatch(getForgotSuccess(json.data.user));
		});
};


export const gettingForgot = () => ({
	type: types.APPLICATION_GETTING_FORGOT
});


export const getForgotSuccess = (user) => ({
	type: types.APPLICATION_GET_FORGOT_SUCCESS,
	user
});


export const getForgotFailure = (errors) => ({
	type: types.APPLICATION_GET_FORGOT_FAILURE,
	errors
});


// reset


export const reset = (token, password) => (dispatch) => {
	dispatch(resetting());
	return publicGrapher(
		`mutation UpdateUserPasswordReset($token: String!, $password: String!) {
			user: updateUserPasswordReset(token: $token, password: $password) {
				${USER_FIELDS}
			}
		}`, { token, password })
		.then(json => {
			if (json.errors) { return dispatch(resetFailure(json.errors)); }
			return dispatch(resetSuccess());
		});
};


export const resetting = () => ({
	type: types.APPLICATION_RESETTING
});


export const resetSuccess = () => ({
	type: types.APPLICATION_RESET_SUCCESS
});


export const resetFailure = (errors) => ({
	type: types.APPLICATION_RESET_FAILURE,
	errors
});


//
// invite
//


export const getInvite = (token) => (dispatch) => {
	dispatch(gettingInvite());
	return publicGrapher(
		`query GetUserByInviteAcceptToken($token: String!) {
			user: getUserByInviteAcceptToken(token: $token) {
				${USER_FIELDS}
			}
		}`, { token })
		.then(json => {
			if (json.errors) { return dispatch(getInviteFailure(json.errors)); }
			return dispatch(getInviteSuccess(json.data.user));
		});
};


export const gettingInvite = () => ({
	type: types.APPLICATION_GETTING_INVITE
});


export const getInviteSuccess = (user) => ({
	type: types.APPLICATION_GET_INVITE_SUCCESS,
	user
});


export const getInviteFailure = (errors) => ({
	type: types.APPLICATION_GET_INVITE_FAILURE,
	errors
});


//
// accept
//


export const accept = (token, firstName, lastName, password) => (dispatch) => {
	dispatch(accepting);
	return publicGrapher(
		`mutation UpdateUserInviteAccept($token: String, $firstName: String, $lastName: String, $password: String) {
			user: updateUserInviteAccept(token: $token, firstName: $firstName, lastName: $lastName, password: $password) {
				id firstName lastName
				photo { url }
				emails { id address verified }
			}
		}`, { token, firstName, lastName, password })
		.then(json => {
			if (json.errors) { return dispatch(acceptFailure(json.errors)); }
			return dispatch(acceptSuccess(json.data.user));
		});
};


export const accepting = () => ({
	type: types.APPLICATION_ACCEPTING
});


export const acceptSuccess = (user) => ({
	type: types.APPLICATION_ACCEPT_SUCCESS,
	user
});


export const acceptFailure = (errors) => ({
	type: types.APPLICATION_ACCEPT_FAILURE,
	errors
});


//
// profile
//


export const updateProfile = (id, firstName, lastName, photo, photoRect) => (dispatch) => {
	dispatch(updatingProfile());
	const user = { ...{ firstName, lastName }, ...(photo ? { photo: 'photo', photoRect } : null) };
	const uploads = photo ? [{ name: 'photo', value: photo }] : null;
	return grapher(
		`mutation UpdateUser($id: ID!, $user: UpdateUserInput) {
			user: updateUser(id: $id, user: $user) {
				id firstName lastName
				photo { url }
				emails { id address verified }				
			}
		}`,
		{ id, user }, uploads
	)
		.then(json => {
			if (json.errors) { return dispatch(updateProfileFailure(json.errors)); }
			return dispatch(updateProfileSuccess(json.data.user));
		});
};


export const updatingProfile = () => ({
	type: types.UPDATING_PROFILE
});


export const updateProfileSuccess = (user) => ({
	type: types.UPDATE_PROFILE_SUCCESS,
	user
});


export const updateProfileFailure = (errors) => ({
	type: types.UPDATE_PROFILE_FAILURE,
	errors
});


//
// notification
//


export const applicationNotificacion = (value) => (dispatch) => {
	const id = _.uniqueId('notification-');
	setTimeout(() => dispatch(applicationNotificacionDismiss(id)), 5000);
	dispatch({ type: types.APPLICATION_NOTIFICATION, id, value });
};


export const applicationNotificacionDismiss = (id) => ({
	type: types.APPLICATION_NOTIFICATION_DISMISS,
	id
});
