import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ConnectWithNimbox } from './_Components';


//
// RegisterSuccess
//


class RegisterSuccess extends Component {

    render() {

        return (
            <AuthenticateLayout>

                <h2>Registro completo</h2>

                <div className="form-group">
                    Gracias por registrarte. Ya puedes <Link to="/login">conectarte</Link> a nimbox con tu correo electrónico y clave.
                </div>

                <div className="form-group">
                    En unos minutos recibirás un email con un link para verificar tu email. Tan pronto lo recibas presiónalo. El link expirará en un día.
                </div>

                <TermsOfUse />

                <div>
                    <ConnectWithNimbox />
                </div>

            </AuthenticateLayout>

        );

    }

}


//
// api
//


export default RegisterSuccess;
