import { connect } from 'react-redux';
import BoxApplicationBusinessPermission from './BoxApplicationBusinessPermission';

import { createBoxApplicationPermission, deleteBoxApplicationPermission, createBoxApplicationBusinessPermission, deleteBoxApplicationBusinessPermission } from './actions';


export default connect(

    (state, ownProps) => {
        const permission = state.application.permissions.find((p) => p.box && (p.box.id === ownProps.box.id));
        return { 
            authorized: permission && (permission.role === 'OWNER' || permission.role === 'ADMINISTRATOR'), 
        };
    },
    
    (dispatch) => ({ 
        onCreateApplicationPermission: (boxId, applicationId) => dispatch(createBoxApplicationPermission(boxId, applicationId)),
        onDeleteApplicationPermission: (boxId, applicationId) => dispatch(deleteBoxApplicationPermission(boxId, applicationId)),
        onCreateApplicationBusinessPermission: (boxId, applicationId, businessId) => dispatch(createBoxApplicationBusinessPermission(boxId, applicationId, businessId)),
        onDeleteApplicationBusinessPermission: (boxId, applicationId, businessId) => dispatch(deleteBoxApplicationBusinessPermission(boxId, applicationId, businessId))
    })

)(BoxApplicationBusinessPermission);
