import React, { Component } from 'react';

import classnames from 'classnames';


class MemberList extends Component {

    handleChangeRole = (e, boxId, userId) => {
        e.preventDefault();
        this.props.onUpdateMemberRole(boxId, userId, e.target.value);
    };

    render() {

        const { user, box, members } = this.props;
        const { handleChangeRole } = this;

        const member = members.find((m) => m.user.id === user.id);
        const administrator = member.role === 'OWNER' || member.role === 'ADMINISTRATOR';

        return (
            <div>
                {members.map(member =>
                    <div key={member.id} className={classnames('form-row', 'px-2', 'pt-2', 'pb-2', { 'bg-info': member.user.id === user.id, 'rounded': member.user.id === user.id })}>
                        <div className="col-auto col-sm">
                            <div className="form-control-plaintext">{member.user.firstName}</div>
                        </div>
                        <div className="col-6 col-sm">
                            <div className="form-control-plaintext">{member.user.lastName}</div>
                        </div>
                        <div className="col-7 col-sm-4">
                            {member.user.emails.map(email =>
                                <div key={email.id} className="form-control-plaintext text-truncate">
                                    <a href={`mailto:${email.address}`}>{email.address}</a>
                                </div>
                            )}
                        </div>
                        <div className="col-5 col-sm-3">
                            <select value={member.role} onChange={(e) => handleChangeRole(e, box.id, member.user.id)} disabled={!administrator || (member.role === 'OWNER')} className="form-control">
                                {member.role === 'OWNER' && <option value="OWNER">Propietario</option>}
                                <option value="ADMINISTRATOR">Administrador</option>
                                <option value="USER">Usuario</option>
                            </select>
                        </div>
                    </div>
                )}
            </div>
        );

    }

}


export default MemberList;
