import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withContainerLayout } from '../Layouts/ContainerLayout';

import AuthenticatedRoute from '../Authenticate/AuthenticatedRoute';

import HomePage from '../Home/HomePageContainer';
import ProfilePageContainer from '../Profile/ProfilePageContainer';
import BoxesRedirectContainer from '../Boxes/BoxesRedirectContainer';
import BoxesPageContainer from '../Boxes/BoxesPageContainer';
import UpdateBusinessContainer from '../Businesses/UpdateBusinessContainer';

import LoginContainer from '../Authenticate/LoginContainer';

import RegisterContainer from '../Authenticate/RegisterContainer';
import RegisterSuccessContainer from '../Authenticate/RegisterSuccessContainer';
import VerifyContainer from '../Authenticate/VerifyContainer';

import ForgotContainer from '../Authenticate/ForgotContainer';
import ForgotSuccessContainer from '../Authenticate/ForgotSuccessContainer';
import ResetContainer from '../Authenticate/ResetContainer';
import ResetSuccessContainer from '../Authenticate/ResetSuccessContainer';

import InviteContainer from '../Authenticate/InviteContainer';
import InviteSuccessContainer from '../Authenticate/InviteSuccessContainer';

import TermsOfUse from '../Pages/TermsOfUse';

import NotFound from '../Pages/NotFound';


//
// Application
//


class ApplicationInitialized extends Component {

	render() {

		return (
			<Switch>

				<Route exact path="/login" component={LoginContainer} />

				<Route exact path="/register" component={RegisterContainer} />
				<Route exact path="/register/success" component={RegisterSuccessContainer} />

				<Route exact path="/email/verify" component={VerifyContainer} />

				<Route exact path="/password/forgot" component={ForgotContainer} />
				<Route exact path="/password/forgot/success" component={ForgotSuccessContainer} />

				<Route exact path="/password/reset" component={ResetContainer} />
				<Route exact path="/password/reset/success" component={ResetSuccessContainer} />

				<Route exact path="/invite" component={InviteContainer} />
				<Route exact path="/invite/success" component={InviteSuccessContainer} />

				<AuthenticatedRoute exact path="/" component={withContainerLayout(HomePage)} />
				<AuthenticatedRoute exact path="/profile" component={withContainerLayout(ProfilePageContainer)} />
				<AuthenticatedRoute exact path="/boxes" component={withContainerLayout(BoxesRedirectContainer)} />
				<AuthenticatedRoute exact path="/boxes/:boxId/:tab" component={withContainerLayout(BoxesPageContainer)} />
				<AuthenticatedRoute exact path="/boxes/:boxId/businesses/:businessId" component={withContainerLayout(UpdateBusinessContainer)} />

				<Route exact path="/legal/terms-of-use" component={withContainerLayout(TermsOfUse)} />

				<Route component={withContainerLayout(NotFound)} />

			</Switch>
		);

	}

}


//
// api
//


const mapStateToProps = (state, ownProps) => ({

	token: state.application.token

});


export default withRouter(connect(mapStateToProps)(ApplicationInitialized));
