import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BoxesCreatePage from './BoxesCreatePage';

import { createBox } from './actions';


export default withRouter(connect(
    
    (state, ownProps) => ({
        create: state.boxes.createBox
    }),

    (dispatch, ownProps) => ({
        onSubmit: (name) => dispatch(createBox(name, (box) => ownProps.history.push(`/boxes/${box.id}/members`)))
    })

)(BoxesCreatePage));
