import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import querystring from 'qs';

import Reset from './Reset';
import { getForgot, reset } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

    connect(

        (state) => ({

            getAction: state.application.getForgot,
            getInvalids: selectStatusInvalids(state.application.getForgot),

            user: state.application.forgotUser,

            action: state.application.reset,
            invalids: selectStatusInvalids(state.application.reset)

        }),

        (dispatch, ownProps) => {
            var qs = querystring.parse(ownProps.location.search, { ignoreQueryPrefix: true });
            return {
                onGet: () => dispatch(getForgot(qs.token)),
                onSubmit: (password) => dispatch(reset(qs.token, password))
            };
        }

    ),

    lifecycle({
        componentDidMount() {
            this.props.onGet();
        }
    })

)(Reset);
