import React from 'react';
import { connect } from 'react-redux';


export const BoxName = connect(
    (state) => ({ user: state.application.user })
)(
    ({ box, user }) => (
        <span>{!box.user ? box.name : `Personal${box.user.id !== user.id ? ` de ${box.user.firstName}` : ''}`}</span>
    )
);


export const BusinessName = connect(
    (state) => ({ user: state.application.user })
)(
    ({ business, user }) => (
        <span>{!business.user ? business.name : `Personal${business.user.id !== user.id ? ` de ${business.user.firstName}` : ''}`}</span>
    )
);
