import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';
import UpdateBusiness from './UpdateBusiness';

import { getBusiness, updateBusiness } from './actions';
import { selectStatusInvalids } from '../store/selectors/form';
import { UPDATE_BUSINESS_SUCCESS } from './types';


export default compose(

    connect(

        (state, ownProps) => ({

            business: state.businesses.business,

            getAction: { status: 'I' },

            updateAction: state.businesses.update,
            updateInvalids: selectStatusInvalids(state.businesses.update)

        }),

        (dispatch, ownProps) => ({
            onGet: () => dispatch(getBusiness(ownProps.match.params.businessId)),
            onCancel: () => ownProps.history.goBack(),
            onSubmit: (business, emails, phones, addresses) => dispatch(updateBusiness(ownProps.match.params.businessId, business, emails, phones, addresses))
                .then(action => action.type === UPDATE_BUSINESS_SUCCESS && ownProps.history.goBack())
        })

    ),

    lifecycle({
        componentDidMount() {
            this.props.onGet();
        }
    })

)(UpdateBusiness);
