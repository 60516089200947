import React, { Component } from 'react';

import logo from '../Images/nimbox-logo.svg';


//
// AuthenticateLayout
//


class AuthenticateLayout extends Component {

    constructor(props) {

        super(props);
        this.state = {
            backgroundImage: null
        };

        this.pageBackgroundNode = null;
        this.handleLoad = this.handleLoad.bind(this);

    }

    componentDidMount() {

        const i = Math.floor(Math.random() * 10) + 1;
        const backgroundImage = `/images/backgrounds/background-do-${i}.jpg`;

        this.setState({ backgroundImage });

        this.unmounted = false;
        const image = new Image();
        image.onload = () => this.handleLoad();
        image.src = backgroundImage;

    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    handleLoad() {
        if (!this.unmounted) {
            this.pageBackgroundNode.style.backgroundImage = `url(${this.state.backgroundImage})`;
            this.pageBackgroundNode.style.opacity = 1;
        }
    }

    render() {

        const { children } = this.props;

        return (
            <div className="login-page-container">
                <div className="page-background" ref={(node) => { this.pageBackgroundNode = node; }} />
                <div className="main-container">

                    <div className="main-body">
                        <div className="login-logo">
                            <img src={logo} style={{ width: '15rem' }} alt="nimbox" />
                        </div>
                        <div className="login-form">
                            {children}
                        </div>
                    </div>

                    <div className="main-footer">
                        <div className="footer-copyright">© Copyright 2010-2018 Nimbox Technologies LTD.<br />All Rights Reserved.</div>
                    </div>

                </div>
            </div>
        );

    }

}


//
// api
//


AuthenticateLayout.propTypes = {
};


export default AuthenticateLayout;
