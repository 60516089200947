import * as types from './Types';
import * as boxesTypes from '../Boxes/types';


const initial = {

	initialize: { status: 'I' },

	authenticate: { status: 'I' },
	user: null, // only when 'S'
	token: null, // only when 'S'
	permissions: [],

	register: { status: 'I' },
	verify: { status: 'I' },

	forgot: { status: 'I' },

	getForgot: { status: 'I' },
	reset: { status: 'I' },
	forgotUser: null,

	getInvite: { status: 'I' },
	accept: { status: 'I' },
	inviteUser: null,

	updateProfile: { status: 'I' },

	notifications: []

};


const application = (state = initial, action) => {

	switch (action.type) {

		// initialize

		case types.APPLICATION_INITIALIZING:
			return { ...state, initialize: { status: 'P' } };

		case types.APPLICATION_INITIALIZE_SUCCESS:
			return { ...state, initialize: { status: 'S' } };

		case types.APPLICATION_INITIALIZE_FAILURE:
			return { ...state, initialize: { status: 'F', error: action.error } };

		// login

		case types.APPLICATION_LOGGING_IN:
			return { ...state, authenticate: { status: 'P' } };

		case types.APPLICATION_LOGIN_SUCCESS:
			return {
				...state, authenticate: { status: 'S' },
				user: action.user,
				token: action.user.accessToken,
				permissions: action.permissions
			};

		case types.APPLICATION_LOGIN_FAILURE:
			return { ...state, authenticate: { status: 'F', errors: action.errors } };

		case types.APPLICATION_LOGIN_RESET:
			return { ...state, authenticate: { status: 'I' } };

		// register

		case types.APPLICATION_REGISTERING:
			return {
				...state,
				register: { status: 'P' }
			};

		case types.APPLICATION_REGISTER_SUCCESS:
			return {
				...state,
				register: { status: 'S' }
			};

		case types.APPLICATION_REGISTER_FAILURE:
			return {
				...state,
				register: { status: 'F', errors: action.errors }
			};

		case types.APPLICATION_REGISTER_RESET:
			return {
				...state,
				register: { status: 'I' }
			};

		// verify

		case types.APPLICATION_VERIFYING:
			return {
				...state,
				verify: { status: 'P' }
			};

		case types.APPLICATION_VERIFY_SUCCESS:
			return {
				...state,
				verify: { status: 'S' }
			};

		case types.APPLICATION_VERIFY_FAILURE:
			return {
				...state,
				verify: { status: 'F', errors: action.errors }
			};

		// forgot

		case types.APPLICATION_FORGOTTING:
			return { ...state, forgot: { status: 'P', errors: state.forgot.errors } };

		case types.APPLICATION_FORGOT_SUCCESS:
			return { ...state, forgot: { status: 'S' } };

		case types.APPLICATION_FORGOT_FAILURE:
			return { ...state, forgot: { status: 'F', errors: action.errors } };

		case types.APPLICATION_FORGOT_RESET:
			return { ...state, forgot: { status: 'I' } };

		// get forgot

		case types.APPLICATION_GETTING_FORGOT:
			return { ...state, getForgot: { status: 'P' } };

		case types.APPLICATION_GET_FORGOT_SUCCESS:
			return { ...state, getForgot: { status: 'S' }, forgotUser: action.user };

		case types.APPLICATION_GET_FORGOT_FAILURE:
			return { ...state, getForgot: { status: 'F', errors: action.errors } };

		// reset

		case types.APPLICATION_RESETTING:
			return { ...state, reset: { status: 'P', errors: state.reset.errors } };

		case types.APPLICATION_RESET_SUCCESS:
			return { ...state, reset: { status: 'S' } };

		case types.APPLICATION_RESET_FAILURE:
			return { ...state, reset: { status: 'F', errors: action.errors } };

		// get invite

		case types.APPLICATION_GETTING_INVITE:
			return { ...state, getInvite: { status: 'P' } };

		case types.APPLICATION_GET_INVITE_SUCCESS:
			return { ...state, getInvite: { status: 'S' }, inviteUser: action.user };

		case types.APPLICATION_GET_INVITE_FAILURE:
			return { ...state, getInvite: { status: 'F', errors: action.errors } };

		// accept

		case types.APPLICATION_ACCEPTING:
			return { ...state, accept: { status: 'P' } };

		case types.APPLICATION_ACCEPT_SUCCESS:
			return { ...state, accept: { status: 'S' }, inviteUser: action.user };

		case types.APPLICATION_ACCEPT_FAILURE:
			return { ...state, accept: { status: 'F', errors: action.errors } };


		// profile

		case types.UPDATING_PROFILE:
			return {
				...state,
				updateProfile: { status: 'P' }
			};

		case types.UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				user: action.user,
				updateProfile: { status: 'S' }
			};

		case types.UPDATE_PROFILE_FAILURE:
			return {
				...state,
				updateProfile: { status: 'F', errors: action.errors }
			};

		case boxesTypes.CREATE_BOX_SUCCESS:
			return {
				...state,
				permissions: [...state.permissions, ...action.box.members]
			};

		// notifications

		case types.APPLICATION_NOTIFICATION:
			return { ...state, notifications: [...state.notifications, { id: action.id, value: action.value }] };

		case types.APPLICATION_NOTIFICATION_DISMISS:
			const i = state.notifications.findIndex((notification) => notification.id === action.id);
			return { ...state, notifications: [...state.notifications.slice(0, i), ...state.notifications.slice(i + 1)] }

		// default

		default:
			return state;

	}

};


export default application;
