import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { grapher } from '../util/request';

import classnames from 'classnames';


let loaded = false;
let currencies = [
    { code: 'DOP' },
    { code: 'USD' },
    { code: 'VES' }
];


class SelectCurrency extends Component {

    state = { currencies: [...currencies] };

    componentDidMount() {
        this.mounted = true;
        if (!loaded) {
            grapher('query { currencies: getCurrencies { code } }')
                .then(json => {
                    if (json.errors) { return; }
                    loaded = true;
                    currencies = json.data.currencies;
                    if (this.mounted) {
                        this.setState({ currencies: json.data.currencies });
                    }
                });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {

        const { name, value, onChange, className } = this.props;
        const { currencies } = this.state;

        return (
            <select name={name} value={value} onChange={onChange} className={classnames('form-control', className)}>
                <option selected value="XXX" disabled>-- Selecciona una moneda --</option>
                {currencies && currencies.map((currency) => <option key={currency.code} value={currency.code}>{currency.code}</option>)}
            </select>
        );

    }

}


// api


SelectCurrency.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SelectCurrency;
