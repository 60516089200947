import React from 'react';
import { Link } from 'react-router-dom';


export const TermsOfUse = () => (
    <div className="form-group">
        Al acceder este sitio estas de acuerdo con nuestros <Link to="/legal/terms-of-use">términos de uso</Link>.
    </div>
);


export const ForgotMyPassword = () => (
    <div>
        ¡Auxilio! <Link to="/password/forgot">Olvidé mi clave</Link>.
    </div>
);


export const RegisterWithNimbox = () => (
    <div>
        ¿Nuevo a nimbox? <Link to="/register">Regístrate</Link>.
    </div>
);


export const ConnectWithNimbox = () => (
    <div>
        ¿Ya estás registrado con nimbox? <Link to="/login">Conéctate</Link>.
    </div>
);
