import React, { Component } from 'react';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ConnectWithNimbox } from './_Components';


//
// ForgotSuccess
//


class ForgotSuccess extends Component {

    render() {

        return (
            <AuthenticateLayout>

                <h2>Email enviado</h2>

                <div className="form-group">
                    Si tu dirección está en nuestros registros, en unos minutos recibirás un email con un link para recuperar tu clave. Tan pronto lo recibas presiónalo. El link expirará en un día.
                </div>

                <TermsOfUse />

                <div>
                    <ConnectWithNimbox />
                </div>

            </AuthenticateLayout>

        );

    }

}


//
// api
//


export default ForgotSuccess;
