import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import querystring from 'qs';

import Invite from './Invite';
import { getInvite, accept } from '../Application/Actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default compose(

    connect(

        (state) => ({

            getAction: state.application.getInvite,
            getInvalids: selectStatusInvalids(state.application.getInvite),

            user: state.application.inviteUser,

            action: state.application.accept,
            invalids: selectStatusInvalids(state.application.accept)

        }),

        (dispatch, ownProps) => {
            const qs = querystring.parse(ownProps.location.search, { ignoreQueryPrefix: true });
            return {
                onGet: () => dispatch(getInvite(qs.token)),
                onSubmit: (firstName, lastName, password) => dispatch(accept(qs.token, firstName, lastName, password))
            };
        }

    ),

    lifecycle({
        componentDidMount() {
            this.props.onGet();
        }
    })

)(Invite);
