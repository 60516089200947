import * as types from './Types';
import { APPLICATION } from '../config';

import { createStore, compose, applyMiddleware } from 'redux';
import ReactGA from 'react-ga';

import { persistStore } from 'redux-persist';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

import reducer from './ApplicationReducer';

import _ from 'lodash';


//
// store
//


let composer = compose;
let middleware = [thunk];

if (process.env.NODE_ENV !== 'production' || process.env.REACT_APP_NIMBOX_ENV === 'testing') {
    composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    middleware = [...middleware, logger];
}

let store;
if (process.env.NODE_ENV === 'production') {
    const trackEvent = () => (next) => (action) => {
        ReactGA.set({ dimension1: APPLICATION, userId: _.get(store.getState(), 'application.user.id') });
        ReactGA.event({ category: 'action', action: action.type });
        const result = next(action);
        return result;
    };
    middleware = [...middleware, trackEvent];
}

store = createStore(
    (state, action) => (action.type === types.APPLICATION_LOGOUT) ? reducer(undefined, action) : reducer(state, action),
    composer(applyMiddleware(...middleware))
);

const persistor = persistStore(store);


export { store, persistor };
