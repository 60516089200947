import * as types from './types';
import _ from 'lodash';
import { grapher } from '../util/request';

import { MEMBER_FIELDS } from '../Boxes/actions';


//
// findUserByUsername
//


export const findUserByUsername = (username) => (dispatch) => {
    dispatch(findingUserByUsername(username));
    return grapher(
        `query FindUserByUsername($username: String!) {
            user: findUserByUsername(username: $username) {
                id
                firstName
                lastName
            }
        }`, { username }
    )
        .then(json => {
            if (json.errors) { return dispatch(findUserByUsernameFailure(json.errors)); }
            return dispatch(findUserByUsernameSuccess(json.data.user));
        });
}


export const findingUserByUsername = (username) => ({
    type: types.FINDING_USER_BY_USERNAME,
    username
});


export const findUserByUsernameSuccess = (user) => ({
    type: types.FIND_USER_BY_USERNAME_SUCCESS,
    user
});


export const findUserByUsernameFailure = (errors) => ({
    type: types.FIND_USER_BY_USERNAME_FAILURE,
    errors
});


//
// createMember
//


export const createMember = (boxId, emailAddress, role, firstName, lastName) => (dispatch) => {
    dispatch(creatingMember());
    return grapher(
        `mutation CreateMember($boxId: ID! $emailAddress: String! $role: MemberRole! $firstName: String! $lastName: String!) {
            member: createMember(boxId: $boxId emailAddress: $emailAddress role: $role firstName: $firstName lastName: $lastName) {
                ${MEMBER_FIELDS}
                box { id name }
            }
        }`,
        {
            boxId, emailAddress, role, firstName, lastName
        })
        .then(json => {
            if (json.errors) { return dispatch(createMemberFailure(json.errors)); }
            return dispatch(createMemberSuccess(_.omit(json.data.member, 'box'), json.data.member.box));
        });
};


export const creatingMember = () => ({
    type: types.CREATING_MEMBER
});


export const createMemberSuccess = (member, box) => ({
    type: types.CREATE_MEMBER_SUCCESS,
    member,
    box
});


export const createMemberFailure = (errors) => ({
    type: types.CREATE_MEMBER_FAILURE,
    errors
});


export const createMemberReset = () => ({
    type: types.CREATE_MEMBER_RESET
});


//
// updateMemberRole
//


export const updateMemberRole = (boxId, userId, role) => (dispatch) => {
    dispatch(updatingMemberRole());
    return grapher(
        `mutation UpdateMemberRole($boxId: ID!, $userId: ID!, $role: MemberRole!) {
            member: updateMemberRole(boxId: $boxId, userId: $userId, role: $role) {
                ${MEMBER_FIELDS}
                box { id name }
            }
        }`, { boxId, userId, role }
    )
        .then(json => {
            if (json.errors) { return dispatch(updateMemberRoleFailure(json.errors)); }
            return dispatch(updateMemberRoleSuccess(_.omit(json.data.member, 'box'), json.data.member.box));
        });
};


export const updatingMemberRole = () => ({
    type: types.UPDATING_MEMBER_ROLE
});


export const updateMemberRoleSuccess = (member, box) => ({
    type: types.UPDATE_MEMBER_ROLE_SUCCESS,
    member,
    box
});


export const updateMemberRoleFailure = (errors) => ({
    type: types.UPDATE_MEMBER_ROLE_FAILURE,
    errors
});
