import * as types from './types';
import * as memberTypes from '../Members/types'
import * as businessesTypes from '../Businesses/types';


const initial = {

    get: { status: 'I' },
    box: null,

    createBox: { status: 'I' }

};


const reducer = (state = initial, action) => {

    switch (action.type) {

        case types.GETTING_BOX_PERMISSIONS:
            return {
                ...state,
                get: { status: 'P' }
            };

        case types.GET_BOX_PERMISSIONS_SUCCESS:
            return {
                ...state,
                get: { status: 'S' },
                box: action.box
            };

        case types.CREATE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS:
        case types.DELETE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS:
            return {
                ...state,
                box: action.box
            };

        case types.CREATE_MEMBER_APPLICATION_PERMISSION_SUCCESS:
        case types.DELETE_MEMBER_APPLICATION_PERMISSION_SUCCESS:
        case types.CREATE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS:
        case types.DELETE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS:
            return {
                ...state,
                box: {
                    ...state.box,
                    members: state.box.members.map((m) => m.id === action.member.id ? action.member : m)
                }
            };

        case types.CREATING_BOX:
            return { ...state, createBox: { status: 'P', errors: state.createBox.errors } };

        case types.CREATE_BOX_SUCCESS:
            return { ...state, createBox: { status: 'S' } };

        case types.CREATE_BOX_FAILURE:
            return { ...state, createBox: { status: 'F', errors: action.errors } };

        case types.CREATE_BOX_RESET:
            return { ...state, createBox: { status: 'I' } };

        case memberTypes.CREATE_MEMBER_SUCCESS:
            if (state.box.id !== action.box.id) {
                return state;
            }
            return {
                ...state,
                box: {
                    ...state.box,
                    members: [...state.box.members, action.member]
                }
            };

        case memberTypes.UPDATE_MEMBER_ROLE_SUCCESS:
            if (state.box.id !== action.box.id) {
                return state;
            }
            return {
                ...state,
                box: {
                    ...state.box,
                    members: state.box.members.map(m => m.id === action.member.id ? action.member : m)
                }
            };

        case businessesTypes.CREATE_BUSINESS_SUCCESS:
            if (state.box.id !== action.box.id) {
                return state;
            }
            return {
                ...state,
                box: {
                    ...state.box,
                    businesses: [...state.box.businesses, action.business]
                }
            };

        default:
            return state;

    }

};


export default reducer;
