import React, { Component } from 'react';

import _ from 'lodash';


class BoxApplicationBusinessPermission extends Component {

    handleToggleApplicationPermission = (e, boxId, applicationId) => {
        e.preventDefault();
        if (e.target.checked) {
            this.props.onCreateApplicationPermission(boxId, applicationId);
        } else {
            this.props.onDeleteApplicationPermission(boxId, applicationId);
        }
    };

    handleToggleApplicationBusinessPermission = (e, boxId, applicationId, businessId) => {
        e.preventDefault();        
        if (e.target.checked) {
            this.props.onCreateApplicationBusinessPermission(boxId, applicationId, businessId);
        } else {
            this.props.onDeleteApplicationBusinessPermission(boxId, applicationId, businessId);
        }
    };

    render() {

        const { box, authorized, applications, businesses } = this.props;
        const { handleToggleApplicationPermission, handleToggleApplicationBusinessPermission } = this;

        if (applications.length === 0) {
            return (
                <div className="text-center my-5">
                    <p>No hay aplicaciones registradas.</p>
                </div>
            );
        }

        // boxChecks converts the box permission structure to a checkable map
        // boxChecks.applications[application.id] if the application is available
        // boxChecks.permissions[application.id][business.id] if the application and the business are available

        const boxChecks = {
            applications: _.keyBy(box.applications, 'application.id'),
            permissions: _.mapValues(_.groupBy(box.permissions, 'application.id'), p => _.keyBy(p, 'business.id'))
        };

        return (
            <div>

                <div className="form-row">
                    <div className="col"/>
                    <div className="col text-center">aplicación</div>
                    { businesses.map(business =>                                                 
                        <div key={business.id} className="col text-center">
                            {business.name} ({business.id})
                        </div>
                    ) }
                </div>

                { applications.map(application => 
                    <div key={application.id} className="form-row align-items-center">

                        <div className="col">
                            <div>{application.application.name} ({application.application.id})</div>
                        </div>

                        <div className="col text-center">
                            <div className="form-check">
                                <input type="checkbox" 
                                    checked={!!(boxChecks.applications[application.application.id])} 
                                    disabled={!authorized || true} 
                                    onChange={(e) => handleToggleApplicationPermission(e, box.id, application.application.id)}
                                    className="form-check-input"
                                />
                            </div>
                        </div>

                        { businesses.map(business => 
                            <div key={business.id} className="col text-center">
                                <div className="form-check">
                                    <input type="checkbox" 
                                        checked={!!(boxChecks.permissions[application.application.id] && boxChecks.permissions[application.application.id][business.id])} 
                                        disabled={!authorized}
                                        onChange={(e) => handleToggleApplicationBusinessPermission(e, box.id, application.application.id, business.id)}
                                        className="form-check-input"
                                    />
                                </div>
                            </div>
                        ) }
                        
                    </div>
                ) }

            </div>
        );

    }

}


export default BoxApplicationBusinessPermission;
