import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';
import classnames from 'classnames';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ForgotMyPassword, ConnectWithNimbox } from './_Components';


//
// Register
//


class Register extends Component {

    state = {
        emailAddress: '',
        firstName: '',
        lastName: '',
        password: ''
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { emailAddress, firstName, lastName, password } = this.state;
        this.props.onSubmit(emailAddress, firstName, lastName, password);
    };

    render() {

        const { action, invalids } = this.props;
        const { emailAddress, firstName, lastName, password } = this.state;
        const { handleSubmit } = this;

        if (action.status === 'S') {
            return (<Redirect to="/register/success" />);
        }

        const processing = (action.status === 'P');

        const emailAddressMessages = {
            'user.emailAddress.error.null': 'Por favor introduce una dirección de email válida.',
            'user.emailAddress.error.duplicate': 'La dirección de email ya está tomada. Si olvidaste tu clave selecciona el link de olvidé mi clave.'
        };

        return (
            <AuthenticateLayout>

                <h2>Regístrate</h2>

                <form onSubmit={handleSubmit}>

                    <div className="form-row">

                        <div className="col-md form-group">
                            <label><strong>Nombre</strong></label>
                            <input type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                className={classnames('form-control', { 'is-invalid': invalids['firstName'] })}
                            />
                            <div className="invalid-feedback">
                                ¿Cómo te llamas?
							</div>
                        </div>

                        <div className="col-md form-group">
                            <label><strong>Apellido</strong></label>
                            <input type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                className={classnames('form-control', { 'is-invalid': invalids['lastName'] })}
                            />
                            <div className="invalid-feedback">
                                ¿Cuál es tu apellido?
							</div>
                        </div>

                    </div>

                    <div className="form-group">
                        <label><strong>Email</strong></label>
                        <input type="text"
                            name="emailAddress"
                            value={emailAddress}
                            onChange={(e) => this.setState({ emailAddress: e.target.value.replace(/\s/g, '') })}
                            className={classnames('form-control', { 'is-invalid': invalids['emailAddress'] })}
                        />
                        <div className="invalid-feedback">
                            {emailAddressMessages[_.get(invalids, 'emailAddress.value', null)] || 'Por favor introduce una dirección de email válida.'}
                        </div>
                    </div>

                    <div className="form-group">
                        <label><strong>Clave</strong></label>
                        <input type="password"
                            name="password"
                            value={password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            className={classnames('form-control', { 'is-invalid': invalids['password'] })}
                        />
                        <div className="invalid-feedback">
                            La clave debe tener por lo menos 8 caracteres y debe contener por lo menos una letra minúscula, una mayúscula, un número y un caracter especial.
						</div>
                    </div>

                    <div className="form-group">
                        <button type="submit" disabled={processing} className="btn btn-primary btn-block">
                            {!processing ?
                                <span>Regístrame</span> :
                                <span><i className="fa fa-spinner fa-spin" /> Registrándote...</span>
                            }
                        </button>
                    </div>

                    <TermsOfUse />

                    <div>
                        <ForgotMyPassword />
                        <ConnectWithNimbox />
                    </div>

                </form>

            </AuthenticateLayout>

        );

    }

}


//
// api
//


Register.propTypes = {

    action: PropTypes.object.isRequired,
    invalids: PropTypes.object,

    onSubmit: PropTypes.func.isRequired

};


export default Register;
