import React, { Component } from 'react';

import _ from 'lodash';


class MemberApplicationBusinessPermission extends Component {

    handleToggleApplicationPermission = (e, boxId, userId, applicationId) => {
        e.preventDefault();
        if (e.target.checked) {
            this.props.onCreateApplicationPermission(boxId, userId, applicationId);
        } else {
            this.props.onDeleteApplicationPermission(boxId, userId, applicationId);
        }
    };

    handleToggleApplicationBusinessPermission = (e, boxId, userId, applicationId, businessId) => {
        e.preventDefault();        
        if (e.target.checked) {
            this.props.onCreateApplicationBusinessPermission(boxId, userId, applicationId, businessId);
        } else {
            this.props.onDeleteApplicationBusinessPermission(boxId, userId, applicationId, businessId);
        }
    };

    render() {

        const { box, members, authorized, applications, businesses } = this.props;
        const { handleToggleApplicationPermission, handleToggleApplicationBusinessPermission } = this;

        if (applications.length === 0) {
            return (
                <div className="text-center my-5">
                    <p>No hay aplicaciones registradas.</p>
                </div>
            );
        }

        if (members.length === 0) {
            return (
                <div className="text-center">
                    <p>No hay miembros registrados.</p>
                </div>
            );
        }

        // boxChecks converts the box permission structure to a checkable map
        // boxChecks.applications[application.id] if the application is available
        // boxChecks.permissions[application.id][business.id] if the application and the business are available

        const boxChecks = {
            applications: _.keyBy(box.applications, 'application.id'),
            permissions: _.mapValues(_.groupBy(box.permissions, 'application.id'), p => _.keyBy(p, 'business.id'))
        };

        // memberChecks converts the member permission structure to a checkable map
        // memberChecks[member.id].applications[application.id] if the application is available
        // memberChecks[member.id].permissions[application.id][business.id] if the application and the business are available

        const memberChecks =
            _.keyBy(
                _.map(members, member => ({
                    id: member.id, 
                    applications: _.keyBy(member.applications, 'application.id'),
                    permissions: _.mapValues(_.groupBy(member.permissions, 'application.id'), p => _.keyBy(p, 'business.id'))
                })),
                'id'
            );

        return (
            <div>                
                { members.map(member => 
                <div key={member.id} className="mb-4 pb-4 border-bottom">

                    <h5>{member.user.firstName} {member.user.lastName} ({member.id})</h5>
                    
                    <div className="form-row">
                        <div className="col"/>
                        <div className="col text-center">aplicación</div>
                        { businesses.map(business =>                                                 
                            <div key={business.id} className="col text-center">
                                {business.name} ({business.id})
                            </div>
                        ) }
                    </div>

                    { applications.map(application => 
                        <div key={application.id} className="form-row">
                            
                            <div className="col">
                                <div>{application.application.name} ({application.application.id})</div>
                            </div>

                            <div className="col text-center">
                                <div className="form-check">
                                    <input type="checkbox" 
                                        checked={!!(memberChecks[member.id].applications[application.application.id])} 
                                        disabled={!authorized}
                                        onChange={(e) => handleToggleApplicationPermission(e, box.id, member.user.id, application.application.id)}
                                        className="form-check-input"
                                    />
                                </div>
                            </div>
                            
                            { businesses.map(business => 
                                <div key={business.id} className="col text-center">
                                    <div className="form-check">
                                        <input type="checkbox" 
                                            checked={!!(memberChecks[member.id].permissions[application.application.id] && memberChecks[member.id].permissions[application.application.id][business.id])}
                                            disabled={!authorized || !(memberChecks[member.id].applications[application.application.id]) || !(boxChecks.permissions[application.application.id] && boxChecks.permissions[application.application.id][business.id])}
                                            onChange={(e) => handleToggleApplicationBusinessPermission(e, box.id, member.user.id, application.application.id, business.id)}
                                            className="form-check-input"
                                        />
                                    </div>
                                </div>
                            ) }

                        </div>
                    ) }

                </div>                
                ) }
            </div>
        );

    }

}


export default MemberApplicationBusinessPermission;
