import * as types from './types';


const initial = {

    find: { status: 'I' },
    user: null,

    create: { status: 'I' },
    member: null

};


const reducer = (state = initial, action) => {

    switch (action.type) {

        case types.FINDING_USER_BY_USERNAME:
            return { ...state, find: { status: 'P' }, user: null };

        case types.FIND_USER_BY_USERNAME_SUCCESS:
            return { ...state, find: { status: 'S' }, user: action.user };

        case types.FIND_USER_BY_USERNAME_FAILURE:
            return { ...state, find: { status: 'F', errors: action.errors } };

        case types.CREATING_MEMBER:
            return { ...state, create: { status: 'P', errors: state.create.errors } };

        case types.CREATE_MEMBER_SUCCESS:
            return { ...state, create: { status: 'S' }, member: action.member };

        case types.CREATE_MEMBER_FAILURE:
            return { ...state, create: { status: 'F', errors: action.errors } };

        case types.CREATE_MEMBER_RESET:
            return initial;

        default:
            return state;

    }

};


export default reducer;
