import React from 'react';
import { Link } from 'react-router-dom';

import UserIdentifierContainer from './UserIdentifierContainer';

import logo from '../../Images/nimbox-logo.svg';

const Navigator = () => (
    <div className="dash-navigator">

        <div className="dash-navigator-brand">
            <Link to="/"><img src={logo} alt="nimbox" style={{ maxHeight: 30 }} /></Link>
        </div>

        <div className="dash-navigator-right">
            <UserIdentifierContainer />
        </div>

        <div style={{ position: 'absolute ' }}>
            <small className="text-muted           d-inline d-sm-none">&nbsp;xs</small>
            <small className="text-muted d-none d-sm-inline d-md-none">&nbsp;sm</small>
            <small className="text-muted d-none d-md-inline d-lg-none">&nbsp;md</small>
            <small className="text-muted d-none d-lg-inline d-xl-none">&nbsp;lg</small>
            <small className="text-muted d-none d-xl-inline">&nbsp;xl</small>
        </div>

    </div >
);


export default Navigator;
