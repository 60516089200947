import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ForgotMyPassword, RegisterWithNimbox } from './_Components';


//
// Login
//


class Login extends Component {

	state = {
		username: '',
		password: '',
		remember: false
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { username, password, remember } = this.state;
		this.props.onSubmit(username, password, remember);
	};

	render() {

		const { action, invalids, redirect } = this.props;
		const { username, password, remember } = this.state;
		const { handleSubmit } = this;

		const processing = (action.status === 'P');

		return (
			<AuthenticateLayout>

				<h2>Conéctate</h2>

				<form onSubmit={handleSubmit}>

					<div className="form-group">
						<label><strong>Identificación</strong></label>
						<input
							type="text" name="username" value={username}
							autoCorrect="off" autoCapitalize="none"
							onChange={(e) => this.setState({ username: e.target.value.replace(/\s/g, '') })}
							className="form-control"
							placeholder="Email o usuario"
						/>
					</div>

					<div className="form-group">
						<label><strong>Clave</strong></label>
						<input
							type="password" name="password" value={password}
							onChange={(e) => this.setState({ password: e.target.value })}
							className="form-control" placeholder="Clave"
						/>
					</div>

					<div className="form-check form-group">
						<label className="form-check-label">
							<input
								type="checkbox" name="remember" checked={remember}
								onChange={(e) => this.setState({ remember: e.target.checked })}
								className="form-check-input"
							/>&nbsp;Recuérdame
						</label>
					</div>

					{(invalids['username'] || invalids['password']) &&
						<div className="alert alert-danger text-center">
							La identificación o clave son incorrectas.
						</div>
					}

					{invalids['facebook'] &&
						<div className="alert alert-danger text-center">
							{invalids['facebook'].value === 'user.emailAddress.error.duplicate' ?
								<span>Hubo un error tratando de continuar con facebook. El correo <strong>{invalids['facebook'].info}</strong> ya está tomado.  Selecciona el link de olvidé mi clave.</span> :
								<span>Hubo un error tratando de continuar con facebook. Regístrate o selecciona el link de olvidé mi clave.</span>
							}
						</div>
					}

					{invalids['linkedin'] &&
						<div className="alert alert-danger text-center">
							{invalids['linkedin'].value === 'user.emailAddress.error.duplicate' ?
								<span>Hubo un error tratando de continuar con linkedin. El correo <strong>{invalids['linkedin'].info}</strong> ya está tomado.  Selecciona el link de olvidé mi clave.</span> :
								<span>Hubo un error tratando de continuar con linkedin. Regístrate o selecciona el link de olvidé mi clave.</span>
							}
						</div>
					}

					<div className="form-group">
						<button type="submit" disabled={processing} className="btn btn-primary btn-block">
							{!processing && <span>Conéctame</span>}
							{processing && <span><i className="fa fa-spinner fa-fw fa-spin" />&nbsp;Conectándote...</span>}
						</button>
					</div>

					<div className="form-row">
						<div className="col form-group">
							<a href={`/authenticate/facebook${redirect ? `?redirect=${redirect}` : ''}`} className={classnames("btn btn-primary btn-block", { 'disabled': processing })}>
								Continúa con facebook
							</a>
						</div>
						<div className="col form-group">
							<a href={`/authenticate/linkedin${redirect ? `?redirect=${redirect}` : ''}`} className={classnames("btn btn-primary btn-block", { 'disabled': processing })}>
								Continúa con linkedin
							</a>
						</div>
					</div>

					<TermsOfUse />

					<div>
						<ForgotMyPassword />
						<RegisterWithNimbox />
					</div>

				</form>

			</AuthenticateLayout>
		);

	}

}


//
// api
//


Login.propTypes = {

	action: PropTypes.object.isRequired,
	invalids: PropTypes.object.isRequired,

	onSubmit: PropTypes.func.isRequired

};


export default Login;
