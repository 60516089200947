import React, { Component } from 'react';

import classnames from 'classnames';
import _ from 'lodash';


const initial = {
    show: false,
    name: '',
    businessName: '',
    taxId: '',
    errors: []
};


class CreateBusiness extends Component {

    state = { ...initial };

    handleToggleShow = () => {
        if (this.state.show) {
            this.setState({ ...initial, show: false });
            this.props.onReset();
        } else {
            this.setState({ ...initial, show: true });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, businessName, taxId } = this.state;
        this.props.onSubmit(name, businessName, taxId)
            .then((success) => success && this.handleToggleShow());
    };

    render() {

        const { permit, action, invalids, className, } = this.props;
        const { show, name, businessName, taxId } = this.state;
        const { handleToggleShow, handleSubmit } = this;

        if (!permit) { return null; }

        const creating = action.status === 'P';
        const disabled = creating;

        return (

            <div className={classnames('card', className)}>

                <div className="card-header">
                    <h5 className="mb-0">
                        <button onClick={handleToggleShow} className="btn btn-link">Crear Empresa</button>
                    </h5>
                </div>

                <div className={classnames('collapse', { 'show': show })}>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text"
                                    value={name}
                                    onChange={(e) => this.setState({ name: e.target.value.replace(/[^a-zA-Z0-9]/g, '') })}
                                    disabled={disabled}
                                    className={classnames('form-control', { 'is-invalid': invalids['name'] })}
                                />
                                <div className="invalid-feedback">
                                    {{
                                        'business.name.error.duplicate': 'Ese nombre de empresa ya está tomado. Por favor intenta de nuevo con otro nombre.'
                                    }[_.get(invalids, 'name.value')] || 'El nombre de la empresa debe comenzar con una letra y estar seguido de letras o números (sin espacios ni caracteres especiales).'}
                                </div>
                                <small className="form-text text-muted">
                                    Este nombre se muestra en la aplicación y debe ser una versión única y corta del nombre comercial (sin espacios ni caracteres especiales).
                                </small>
                            </div>

                            <div className="form-group">
                                <label>Nombre comercial</label>
                                <input type="text"
                                    value={businessName}
                                    onChange={(e) => this.setState({ businessName: e.target.value })}
                                    disabled={disabled}
                                    className={classnames('form-control', { 'is-invalid': invalids['businessName'] })}
                                />
                                <div className="invalid-feedback">
                                    El nombre comercial es indispensable para crear la empresa.
                                </div>
                                <small className="form-text text-muted">
                                    Este nombre es el que aparece en los documentos de registro de la empresa.
                                </small>
                            </div>

                            <div className="form-group">
                                <label>Identificación fiscal</label>
                                <input type="text"
                                    name="taxId"
                                    value={taxId}
                                    onChange={(e) => this.setState({ taxId: e.target.value })}
                                    disabled={disabled}
                                    className={classnames('form-control', { 'is-invalid': invalids['taxId'] })}
                                />
                                <div className="invalid-feedback">
                                    La identificación fiscal es indispensable para crear la empresa.
                                </div>
                                <small className="form-text text-muted">
                                    Este nombre es el que aparece en los documentos de registro de la empresa.
                                </small>
                            </div>

                            <div>
                                <button type="submit" disabled={disabled} className="btn btn-primary mr-2">
                                    Agregar {creating && <i className="fa fa-spinner fa-spin" />}
                                </button>
                                <button type="button" onClick={handleToggleShow} disabled={disabled} className="btn btn-secondary">Cancelar</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>

        );

    }

}


export default CreateBusiness;
