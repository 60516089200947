import { connect } from 'react-redux';

import ApplicationNotification from './ApplicationNotification';


export default connect(
    (state) => ({
        notifications: state.application.notifications
    })
)(ApplicationNotification);
