import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import classnames from 'classnames';

import AuthenticateLayout from './AuthenticateLayout';
import { TermsOfUse, ForgotMyPassword, RegisterWithNimbox, ConnectWithNimbox } from './_Components';


//
// Reset
//


class Reset extends Component {

	state = {
		password: '',
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { password } = this.state;
		this.props.onSubmit(password);
	};

	render() {

		const { getAction, action, invalids } = this.props;
		const { password } = this.state;
		const { handleSubmit } = this;

		// check that the get action is successful

		if (getAction.status === 'I' || getAction.status === 'P') {
			return (
				<AuthenticateLayout />
			);
		}

		if (getAction.status === 'F') {
			return (
				<AuthenticateLayout>

					<h2>Cambia tu clave</h2>

					<div className="alert alert-danger text-center">
						El link que presionaste para llegar aquí es inválido o expiró. Selecciona el link de olvidé mi clave.
					</div>

					<TermsOfUse />

					<div>
						<ForgotMyPassword />
						<RegisterWithNimbox />
						<ConnectWithNimbox />
					</div>

				</AuthenticateLayout>
			);
		}

		// check that the update action is successful

		if (action.status === 'S') {
			return (<Redirect to="/password/reset/success" />);
		}

		const processing = (action.status === 'P');

		return (
			<AuthenticateLayout>

				<h2>Cambia tu clave</h2>

				<form onSubmit={handleSubmit}>

					<div className="form-group">
						<label><strong>Clave</strong></label>
						<input type="password"
							name="password"
							value={password}
							onChange={(e) => this.setState({ password: e.target.value })}
							className={classnames('form-control', { 'is-invalid': invalids['password'] })}
							placeholder="Nueva clave"
						/>
						<div className="invalid-feedback">
							La clave debe tener por lo menos 8 caracteres y debe contener por lo menos una letra minúscula, una mayúscula, un número y un caracter especial.
						</div>
					</div>

					{invalids['passwordResetToken'] &&
						<div className="alert alert-danger text-center">
							El link que presionaste para llegar aquí es inválido o expiró. Selecciona el link de olvidé mi clave.
						</div>
					}

					<div className="form-group">
						<button type="submit" disabled={processing} className="btn btn-primary btn-block">
							{!processing ?
								<span>Cambia mi clave</span> :
								<span><i className="fa fa-spinner fa-spin" /></span>
							}
						</button>
					</div>

					<TermsOfUse />

					<div>
						<ForgotMyPassword />
						<ConnectWithNimbox />
					</div>

				</form>

			</AuthenticateLayout>
		);

	}

}


//
// api
//


Reset.propTypes = {

	action: PropTypes.object.isRequired,
	invalids: PropTypes.object.isRequired,

	onSubmit: PropTypes.func.isRequired

};


export default Reset;
