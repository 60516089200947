import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { BusinessName } from '../Components/Quarks';


class MemberList extends Component {

    render() {

        const { match, businesses } = this.props;

        return (
            <div className="row">
                { businesses.map(business => 
                <div key={business.id} className="col-12 col-sm-4 col-md-6 col-lg-4 form-group">
                    <div className="card h-100">
                        <div className="card-body">
                            <h4 className="card-title">{business.businessName || 'Sin Nombre'}</h4>
                            <h5 className="card-subtitle text-muted"><BusinessName business={business}/></h5>
                            <Link to={`${match.url}/${business.id}`} className="card-link">Editar</Link>
                        </div>
                    </div>
                </div>
                ) }
            </div>
        );

    }

}


export default MemberList;
