import { createSelector } from 'reselect';
import _ from 'lodash';


export const selectStatus = (status) => status;


export const selectStatusInvalids = createSelector(
    selectStatus,
    (status) => {
        return (!_.isEmpty(status.errors)) ? _.keyBy(_.filter(_.map(status.errors, 'extensions'), ['code', 'plasma-invalid-input']), 'name') : {};
    }
);
