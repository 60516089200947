import React, { Component } from 'react';

import { Page, Title, Container, Content } from '../Layouts/Helium';
import applicationMarket from '../Images/application-market.svg';

import { MARKET_SERVER } from '../config';


class HomePage extends Component {

	render() {

		return (
			<Page>
				<Title><h1 className="my-5">Aplicaciones</h1></Title>
				<Container>
					<Content>
						<a href={`${MARKET_SERVER}/p/market`}><img src={applicationMarket} alt="application-market" style={{ width: '128px', height: '128px' }} /></a>
					</Content>
				</Container>
			</Page>
		);

	}

}


export default HomePage;
