import React, { Component } from 'react';

import classnames from 'classnames';


class ApplicationNotification extends Component {

    state = {
        show: false,
        notifications: null
    };

    static getDerivedStateFromProps(props, state) {

        const { notifications } = props;

        if (props.notifications && props.notifications !== state.notifications) {
            return {
                show: notifications.length > 0,
                notifications
            }
        }
        return null;

    }

    render() {

        const { notifications, children } = this.props
        const { show } = this.state;

        const notification = notifications.length > 0 ? notifications[notifications.length - 1].value : null;

        return (
            <div style={{ height: '100%' }}>
                <div className={classnames('dash-application-notification', { 'show': show })} >
                    {notification ? message(notification) : ''}
                </div>
                {children}
            </div>
        );

    }

}


function message(value) {

    if (value) {

        if (value.code === 'network-error') {
            return `El servidor no está respondiendo (${value.info})`;
        }

    }

    return '';

}


export default ApplicationNotification;
