import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Page, Title, Container, Content } from '../Layouts/Helium';
import { BoxName } from '../Components/Quarks';

import _ from 'lodash';
import classnames from 'classnames';

import MemberListContainer from '../Members/MemberListContainer';
import BoxApplicationBusinessPermissionContainer from './BoxApplicationBusinessPermissionContainer';
import BusinessListContainer from '../Businesses/BusinessListContainer';
import MemberApplicationBusinessPermissionContainer from './MemberApplicationBusinessPermissionContainer.js';

import CreateMemberContainer from '../Members/CreateMemberContainer';
import CreateBoxContainer from './CreateBoxContainer';
import CreateBusinessContainer from '../Businesses/CreateBusinessContainer';


class BoxesPage extends Component {

    replace = (url, part) => {
        const i = url.lastIndexOf('/');
        return url.slice(0, i + 1) + part;
    };

    render() {

        const { match, box, tab, permissions } = this.props;
        const { replace } = this;

        if (!box) { return null; }

        const members = box.members;
        const businesses = box.businesses;
        const applications = box.applications;

        const boxes = _.sortBy(permissions.map(member => member.box), 'name');

        return (
            <Page>

                <Title><h1 className="mt-5 mb-5"><BoxName box={box}/></h1></Title>

                <Container>

                    <Content>

                        <div className="row">

                            <div className="col-md-2">

                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a href="#teams" className="nav-link">Equipos</a>
                                    </li>
                                </ul>

                                <div className="mt-4 mb-4">
                                    <ul className="nav flex-column nav-pills">
                                        {boxes.map(b =>
                                            <li key={b.id} className="nav-item">
                                                <Link to={`/boxes/${b.id}/${tab}`} className={classnames('nav-link', 'text-truncate', { 'active': b.id === box.id })}><BoxName box={b}/></Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </div>

                            <div className="col-md-7 col-lg-6">

                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link to={replace(match.url, 'members')} className={classnames('nav-link', { 'active': tab === 'members' })}>Miembros</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={replace(match.url, 'applications')} className={classnames('nav-link', { 'active': tab === 'applications' })}>Aplicaciones</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={replace(match.url, 'businesses')} className={classnames('nav-link', { 'active': tab === 'businesses' })}>Empresas</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={replace(match.url, 'permissions')} className={classnames('nav-link', { 'active': tab === 'permissions' })}>Permisos</Link>
                                    </li>
                                </ul>

                                <div className="mt-4 mb-4">
                                    {tab === 'members' && <MemberListContainer box={box} members={members} />}
                                    {tab === 'applications' && <BoxApplicationBusinessPermissionContainer box={box} applications={applications} businesses={businesses} />}
                                    {tab === 'businesses' && <BusinessListContainer box={box} businesses={businesses} />}
                                    {tab === 'permissions' && <MemberApplicationBusinessPermissionContainer box={box} businesses={businesses} applications={applications} members={members} permissions={permissions} />}
                                </div>

                            </div>

                            <div className="col-md-3 col-lg-4">

                                <ul className="nav nav-tabs">
                                    <li className="nav-item"><span className="nav-link">Acciones</span></li>
                                </ul>

                                <div className="mt-4 mb-4">
                                    <CreateMemberContainer box={box} className="form-group" />
                                    <CreateBoxContainer box={box} className="form-group" />
                                    <CreateBusinessContainer box={box} className="form-group" />
                                </div>

                            </div>

                        </div>

                    </Content>

                </Container>

            </Page >
        );

    }

}


export default BoxesPage;
