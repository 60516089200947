import * as types from './types';
import _ from 'lodash';
import { grapher } from '../util/request';


//
// getBusiness
//


export const getBusiness = (id) => (dispatch) => {
    dispatch(gettingBusiness());
    return grapher(
        `query GetBusiness($id: ID!) {
            business: getBusiness(id: $id) {
                id
                user { id }
                name
                businessName
                taxId country { code } currency { code }
                emails { id address locus verified }
                phones { id number locus verified }
                addresses { id street reference city state postal country locus }
            }
        }`, { id }
    )
        .then(json => {
            if (json.errors) { return dispatch(getBusinessFailure(json.errors)); }
            return dispatch(getBusinessSuccess(json.data.business));
        });
};


export const gettingBusiness = () => ({
    type: types.GETTING_BUSINESS
});


export const getBusinessSuccess = (business, box) => ({
    type: types.GET_BUSINESS_SUCCESS,
    business,
    box
});


export const getBusinessFailure = (errors) => ({
    type: types.GET_BUSINESS_FAILURE,
    errors
});


//
// createBusiness
//


export const createBusiness = (boxId, name, businessName, taxId) => (dispatch) => {
    dispatch(creatingBusiness());
    return grapher(
        `mutation CreateBusiness($boxId: ID!, $name: String!, $businessName: String, $taxId: String) {
            business: createBusiness(boxId: $boxId, name: $name, businessName: $businessName, taxId: $taxId, country: "DO", currency: "DOP") {
                id
                name
                businessName
                taxId
                box { id name }
            }
        }`, { boxId, name, businessName, taxId }
    )
        .then(json => {
            if (json.errors) { return dispatch(createBusinessFailure(json.errors)); }
            return dispatch(createBusinessSuccess(_.omit(json.data.business, 'box'), json.data.business.box));
        });
};


export const creatingBusiness = () => ({
    type: types.CREATING_BUSINESS
});


export const createBusinessSuccess = (business, box) => ({
    type: types.CREATE_BUSINESS_SUCCESS,
    business,
    box
});


export const createBusinessFailure = (errors) => ({
    type: types.CREATE_BUSINESS_FAILURE,
    errors
});


export const createBusinessReset = () => ({
    type: types.CREATE_BUSINESS_RESET
})


//
// updateBusiness
//


export const updateBusiness = (id, business, emails, phones, addresses) => (dispatch) => {
    dispatch(updatingBusiness());
    return grapher(
        `mutation UpdateBusiness($id: ID!, $business: UpdateBusiness, $emails: UpdateEmailsInput, $phones: UpdatePhonesInput, $addresses: UpdateAddressesInput) {
            business: updateBusiness(id: $id, business: $business, emails: $emails, phones: $phones, addresses: $addresses) {
                id
                name
                businessName
                taxId country { code } currency { code }
                emails { id address locus verified }
                phones { id number locus verified }
                addresses { id street reference city state postal country locus }
            }
        }`, { id, business, emails, phones, addresses }
    )
        .then(json => {
            if (json.errors) { return dispatch(updateBusinessFailure(json.errors)); }
            return dispatch(updateBusinessSuccess(json.data.business));
        });
};


export const updatingBusiness = () => ({
    type: types.UPDATING_BUSINESS
});


export const updateBusinessSuccess = (business) => ({
    type: types.UPDATE_BUSINESS_SUCCESS,
    business
});


export const updateBusinessFailure = (errors) => ({
    type: types.UPDATE_BUSINESS_FAILURE,
    errors
});
