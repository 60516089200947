import React, { Component } from 'react';

import classnames from 'classnames';
import _ from 'lodash';


const initial = {
    show: false,
    emailAddress: '',
    firstName: '',
    lastName: '',
    role: 'USER'
};


class CreateMember extends Component {

    state = { ...initial };

    handleToggleShow = () => {
        if (this.state.show) {
            this.setState({ ...initial, show: false });
            this.props.onReset();
        } else {
            this.setState({ ...initial, show: true });
        }
    }

    findEmailAddress = _.debounce((emailAddress) => {
        this.props.onFind(emailAddress);
    }, 300);

    handleEmailAddressChange = (e) => {
        this.setState({ emailAddress: e.target.value });
        this.findEmailAddress(e.target.value);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.emailAddress, this.state.role, this.state.firstName, this.state.lastName)
            .then((success) => success && this.handleToggleShow());
    };

    render() {

        const { action, invalids, permit, user, className } = this.props;
        const { show, firstName, lastName, emailAddress, role } = this.state;
        const { handleToggleShow, handleEmailAddressChange, handleSubmit } = this;

        if (!permit) { return null; }

        const creating = action.status === 'P';
        const disabled = creating;

        return (

            <div className={classnames('card', className)}>

                <div className="card-header">
                    <h5 className="mb-0">
                        <button onClick={handleToggleShow} className="btn btn-link">Agregar Miembro</button>
                    </h5>
                </div>

                <div className={classnames('collapse', { 'show': show })}>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <label>Email</label>
                                <input type="text"
                                    value={emailAddress}
                                    onChange={handleEmailAddressChange}
                                    disabled={disabled}
                                    className={classnames('form-control', { 'is-invalid': invalids['emailAddress'] })}
                                />
                                <div className="invalid-feedback">
                                    Por favor introduce una dirección de email válida.
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-12 col-lg-6 form-group">
                                    <label>Nombre</label>
                                    <input type="text"
                                        value={user ? user.firstName : firstName}
                                        onChange={(e) => this.setState({ firstName: e.target.value })}
                                        disabled={!!user}
                                        className={classnames('form-control', { 'is-invalid': invalids['firstName'] })}
                                    />
                                    <div className="invalid-feedback">
                                        ¿Cómo se llama?
							        </div>
                                </div>
                                <div className="col-md-12 col-lg-6 form-group">
                                    <label>Apellido</label>
                                    <input type="text"
                                        value={user ? user.lastName : lastName}
                                        onChange={(e) => this.setState({ lastName: e.target.value })}
                                        disabled={!!user}
                                        className={classnames('form-control', { 'is-invalid': invalids['lastName'] })}
                                    />
                                    <div className="invalid-feedback">
                                        ¿Cuál es su apellido?
							        </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Role</label>
                                <select value={role} onChange={(e) => this.setState({ role: e.target.value })} className="form-control">
                                    <option value="ADMINISTRATOR">Administrador</option>
                                    <option value="USER">Usuario</option>
                                </select>
                            </div>

                            <div>
                                <button type="submit" disabled={disabled} className="btn btn-primary mr-2">
                                    Agregar {creating && <i className="fa fa-spinner fa-spin" />}
                                </button>
                                <button type="button" onClick={handleToggleShow} disabled={disabled} className="btn btn-secondary">Cancelar</button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>

        );

    }

}


export default CreateMember;
