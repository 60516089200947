// box

export const CREATE_BOX = 'CREATE_BOX';
export const CREATING_BOX = 'CREATING_BOX';
export const CREATE_BOX_SUCCESS = 'CREATE_BOX_SUCCESS';
export const CREATE_BOX_FAILURE = 'CREATE_BOX_FAILURE';
export const CREATE_BOX_RESET = 'CREATE_BOX_RESET';

export const GET_BOX_PERMISSIONS = 'GET_BOX_PERMISSIONS';
export const GETTING_BOX_PERMISSIONS = 'GETTING_BOX_PERMISSIONS';
export const GET_BOX_PERMISSIONS_SUCCESS = 'GET_BOX_PERMISSIONS_SUCCESS';
export const GET_BOX_PERMISSIONS_FAILURE = 'GET_BOX_PERMISSIONS_FAILURE';

// box permissions

export const CREATE_BOX_APPLICATION_PERMISSION_SUCCESS = 'CREATE_BOX_APPLICATION_PERMISSION_SUCCESS';
export const DELETE_BOX_APPLICATION_PERMISSION_SUCCESS = 'DELETE_BOX_APPLICATION_PERMISSION_SUCCESS';

export const CREATE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS = 'CREATE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS';
export const DELETE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS = 'DELETE_BOX_APPLICATION_BUSINESS_PERMISSION_SUCCESS';

// member permissions

export const CREATE_MEMBER_APPLICATION_PERMISSION_SUCCESS = 'CREATE_MEMBER_APPLICATION_PERMISSION_SUCCESS';
export const DELETE_MEMBER_APPLICATION_PERMISSION_SUCCESS = 'DELETE_MEMBER_APPLICATION_PERMISSION_SUCCESS';

export const CREATE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS = 'CREATE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS';
export const DELETE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS = 'DELETE_MEMBER_APPLICATION_BUSINESS_PERMISSION_SUCCESS';
