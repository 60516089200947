import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import BoxesPage from './BoxesPage';
import { getBoxPermissions } from './actions';


export default compose(
  
    connect(
  
        (state, ownProps) => {
            return {
                
                user: state.application.user,

                boxId: ownProps.match.params.boxId,
                tab: ownProps.match.params.tab,

                get: state.boxes.get,
                box: state.boxes.box,

                createMember: state.boxes.createMember,

                permissions: state.application.permissions

            };
        }, 

        (dispatch) => ({
            onMount: (id) => dispatch(getBoxPermissions(id))
        }),

        (stateProps, dispatchProps, ownProps) => ({ ...ownProps, ...stateProps, ...dispatchProps,
            onMount: () => dispatchProps.onMount(stateProps.boxId)
        })

    ),
  
    lifecycle({ 
        componentDidMount() {
            this.props.onMount();
        }
    })
  
)(BoxesPage);
