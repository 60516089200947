import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { grapher } from '../util/request';

import classnames from 'classnames';


let loaded = false;
let countries = [
    { code: 'DO', description: 'República Dominicana' },
    { code: 'US', description: 'United States' },
    { code: 'VE', description: 'Venezuela' }
];


class SelectCountry extends Component {

    state = { countries: [...countries] };

    componentDidMount() {
        this.mounted = true;
        if (!loaded) {
            grapher('query { countries: getCountries { code description } }')
                .then(json => {
                    if (json.errors) { return; }
                    loaded = true;
                    countries = json.data.countries;
                    if (this.mounted) {
                        this.setState({ countries: json.data.countries });
                    }
                });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {

        const { name, value, onChange, className } = this.props;
        const { countries } = this.state;

        return (
            <select name={name} value={value} onChange={onChange} className={classnames('form-control', className)}>
                <option selected value="XX" disabled>-- Selecciona un país --</option>
                {countries && countries.map((country) => <option key={country.code} value={country.code}>{country.description}</option>)}
            </select>
        );

    }

}


// api


SelectCountry.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


export default SelectCountry;
