import { connect } from 'react-redux';
import CreateBox from './CreateBox';

import * as types from './types';
import { createBox, createBoxReset } from '../Boxes/actions';
import { selectStatusInvalids } from '../store/selectors/form';


export default connect(

    (state) => ({
        action: state.boxes.createBox,
        invalids: selectStatusInvalids(state.boxes.createBox)
    }),

    (dispatch) => ({
        onSubmit: (name) => dispatch(createBox(name))
            .then((action) => action.type === types.CREATE_BOX_SUCCESS),
        onReset: () => dispatch(createBoxReset())
    })

)(CreateBox);
