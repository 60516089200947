import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../Images/404.svg';


class NotFound extends Component {

    render() {

        return (
            <div className="text-center">
                <img src={image} alt="not found" style={{ width: 150 }} />
                <h2>¡Ups!</h2>
                <p className="text-muted" style={{ width: 250, margin: 'auto' }}>
                    Algo terrible pasó y no podemos conseguir la página que buscas.
                    Trata de nuevo desde <Link to="/">el principio</Link>.
                </p>
            </div>
        );

    }

}


export default NotFound;
