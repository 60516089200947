import { connect } from 'react-redux';
import UserIdentifier from './UserIdentifier';

import { logout } from '../Actions';


export default connect(

    (state) => ({
        user: state.application.user
    }),

    (dispatch) => ({
        logout: () => dispatch(logout())
    })

)(UserIdentifier);
