import React, { FunctionComponent } from 'react';

import { Neon } from 'gizmo';


const Footer: FunctionComponent<{}> = () => (
    <Neon.Page.Footer className="py-5">
        <div className="text-center" style={{ fontSize: '0.75rem' }}>Copyright 2019 Nimbox Technologies LTD. All Rights Reserved.</div>
    </Neon.Page.Footer>
);


export default Footer;
